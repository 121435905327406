import React from 'react';

/**
 * Voice File Contexts to avoid props chaining among components
 */
export const VoiceContext = React.createContext({
    // updates the selection for multi voice file
    updateSelectedOption: () => { },
    // available options for multivoicefiles
    getAvailableOptions: () => { },
    // handles the upload file operation
    handleVoiceFileUploadMethod: () => { },
    updateVoiceFilePathState: () => { },
    // handles the close operation for menu
    handleCloseOptionMenu: () => { },
    // handles the delete operation for menu
    handleDeleteOption: () => { },
    // handles the save operation for menu
    handleSaveOptionMenu: () => { },
    // tabs in the menu 
    tabType: "",
    // selected element
    element: {},
    // details of voice file
    voiceFileDetails: {},
    // language selected english or spanish
    selectedLanguage: "",
    // actual option that is selected 
    selectedOption: {},
    // contains voice file upload mentod
    voiceFileUploadMethod: "",
    // contains voice file upload path
    voiceFilePath: "",
    // boolean value to add option or not
    isAddOption: false,
    // details for temp voice file selected
    tempVoiceInfo: {},
    // contains info of temp selected option
    tempSelectedOption: {},

    // UserInputProperties  context :-
    // array of supported languages english or spanish 
    supportedLanguages: [],
    // updates the voice file stateInfo
    updateVoiceFileStateInfo: () => { },
    userInputInvalidInputVoiceFileUploadType: "",
    // path od invalid voice file
    invalidVoiceFilePath: "",
    // info of invalid voice file
    invalidVoiceFileInfo: {}
});

/**
 * Single Voice File Contexts to avoid props chaining among components
 */
export const SingleVoiceContext = React.createContext({
    // Single voice file context :-
    // function to update option and values
    updateOptionAndValues: () => { },
    // function to remove option
    removeOption: () => { },
    // function to add option
    addOption: () => { },
    // function that updates voice file info to the state
    updateVoiceFileStateInfo: () => { },
    // function that updates voice file path to the state
    updateVoiceFilePathState: () => { },
    // function to reset states in component
    resetStates: () => { },
    optionAndVal: {},
    // boolean to check option has zero in option list
    isOptionAndValStartFromZero: false,
    // array of supported languages english or spanish
    supportedLanguages: [],
    // language selected english or spanish
    selectedLanguage: "",
    // name of the voice file upload operation
    voiceFileUploadMethod: "",
    setVoiceFileUploadMethod: "",
    // path of the voice file
    voiceFilePath: "",
    // selected or current element
    element: {},
    // details of voice file
    voiceFileDetails: {}
});