import { createRoot } from 'react-dom/client';
import React from 'react';

import PropertiesView from '../properties-panel/PropertiesView';
import '../../scss/style.scss';
import '../../scss/common.scss';

/**
 * Properties Panel on diagram page
 */
export default class PropertiesPanel {

  constructor(options) {

    const {
      modeler, container
    } = options;

    // createRoot is same as ReactDomRender but it is supported in react 18 or above version
    createRoot(container).render(
      <PropertiesView modeler={modeler} />
    );
  }
}