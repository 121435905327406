// element types for the diagram
export const EL_TYPE = {
  GATEWAY: "bpmn:ExclusiveGateway",
  SUB_PROCESS: "bpmn:SubProcess",
  TASK: "bpmn:Task",
  SEQUENCE_FLOW: "bpmn:SequenceFlow",
  USER_TASK: "bpmn:UserTask",
  SERVICE_TASK: "bpmn:ServiceTask",
  INTERMEDIATE_THROW_EVENT_TASK: "bpmn:IntermediateThrowEvent",
  END_EVENT: "bpmn:EndEvent",
  START_EVENT: "bpmn:StartEvent",
  // Formal expression is used inside bussinessObject of sequence flow when we export the re usable sub process
  FORMAL_EXPRESSION: "bpmn:FormalExpression",
  // Label is the name of the element that we assign to it on right panel
  LABEL: "label",
  BPMN_DIAGRAM: "bpmndi:BPMNDiagram",
  BPMN_PLANE: "bpmndi:BPMNPlane",
  BPMN_PARENT_PROCESS: "BPMNDiagram_Process_1"

};

export const TAB_LIST = "tabList";
// element names for the diagram
export const EL_NAME = {
  END_EVENT_NAME: "EndEvent"
}

// prefixes for default names of various bpmn events
export const DEFAULT_EL_NAME_PREFIX = {
  START: "Start",
  GATEWAY: "Condition",
  SERVICE: "Service",
  INTERMEDIATE: "Intermediate",
  OPTION_INPUT: "Option",
  USER_INPUT: "Input",
  PROMPT: "Prompt",
  HANGUP: "HangUp",
  VOICE: "Voice",
  TRANSFER: "Transfer",
  END: "End",
  SUB_PROCESS: "SubProcess",
  VOICE_RECORD_START: "VoiceRecordStart",
  VOICE_RECORD_STOP: "VoiceRecordStop"
}

export const ELEMENT_COLORS = {
  DEFAULT_CONNECTION_COLOR: "#b8c3e3",
  LINE_AT_NODE_TOP_COLOR: "#3feb73",
  LINE_DIVIDER_COLOR: "#dddd",
  NODE_HEADING_TEXT_COLOR: "#4f7c9e",
  VOICE_FILE_TEXT_COLOR: "#7291a8",
  VOICE_FILE_NAME_COLOR: "#45B39D",
  WHITE_COLOR: "white",
  OUTER_BOX_RECT_COLOR: "#ddd",
  DISABLED_COLOR: "#808080"
}

// Keys from the Task types object that do not have dragstart, only action
export const COMMON_ACTIONS = {
  DISPLAY_INFO: 'display-info',
  DISABLE_TASK: 'disable-task',
  ENABLE_TASK: 'enable-task',
  RE_USABLE_COMPONENT: 're-usable-component'
};
