import React from "react";
import PropTypes from "prop-types";
import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger"; // Assuming you have a tooltip wrapper

/**
 * PmivrSelectOption - A reusable and customizable select dropdown component with tooltip integration.
 * This component provides a styled dropdown menu with support for custom options, placeholder text, and tooltips.
 * It is designed to handle dynamic option lists and manage selected values with ease.
 * 
 * @param {Object} props - Component properties.
 * @param {Function} props.onChange - Callback function triggered when the selected value changes.
 * @param {string} props.selectValue - The currently selected value of the dropdown.
 * @param {Array} props.options - Array of option objects to display in the dropdown. 
 *                                Each object should have a `value` (string) and a `label` (string).
 *                                Example: [{ value: "en", label: "English" }, { value: "es", label: "Spanish" }]
 * @param {string} [props.firstOption="Select"] - Placeholder text displayed as the first unselectable option.
 * @param {string} [props.tooltip="Select Option"] - Tooltip text displayed when hovering over the dropdown.
 * @param {string} [props.cssClass=""] - Additional CSS classes for custom styling of the select element.
 * @param {string} [props.selectedValue=""] - The selected value (if pre-defined).
 * 
 * @returns {JSX.Element} - A styled select dropdown wrapped in a tooltip.
 */
const PmivrSelectOption = ({
    onChange,
    selectValue,
    options = [],
    firstOption = "Select",
    tooltip = "Select Option",
    cssClass = "",
    selectedValue = ""
}) => {
    return (
        <PmivrOverlayTrigger tooltip={tooltip}>
            <select
                className={`pmivr-select ${cssClass}`}
                aria-label="Dropdown select"
                onChange={onChange}
                value={selectValue}
                style={{ color: selectValue ? "rgba(6, 30, 81)" : "rgb(110 110 110)" }}
            >
                <option value="" disabled selected>{firstOption}</option>
                {options.map(({ value, label }, index) => (
                    <option key={index} value={value} style={{ color: "rgba(6, 30, 81)" }}>
                        {label}
                    </option>
                ))}
            </select>
        </PmivrOverlayTrigger>
    );
};

PmivrSelectOption.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectValue: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    firstOption: PropTypes.string,
    tooltip: PropTypes.string,
    cssClass: PropTypes.string,
};

export default PmivrSelectOption;
