import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { VoiceContext } from '../../../contexts/app-context';
import VoicePromptSelection from '../voice-prompt-selection/VoicePromptSelection';

/**
 * Component to render accordion with languages configured and multiprompt selection for voice files like call end, invalid files
 * @param {onSave,voicePromptInfo} props props data from parent component
 * @returns {React.Component} Html code to render call end voice files
 */
const VoicePromptAccordion = (props) => {
    // showVariableOption is for disabling the variable play in case of speech input we do not have variable play
    const { voicePromptInfo, onSave, title = "", showAddPromptBtn = true, showVariableOption = true } = props;
    const { element } = useContext(VoiceContext);
    // languages configured for the flow
    const { languagesConfigured } = useSelector(state => state.client);
    // flag to set the selected language
    const [selectedLanguage, setSelectedLanguage] = useState("");

    // on element change , reset the language as well, otherwise it will show previous element seelcted language
    useEffect(() => {
        setSelectedLanguage(languagesConfigured[0]);
    }, [element]);

    return (
        <Accordion className="mt-3 mb-3 pmivr-accordion" flush>
            <Accordion.Item eventKey={"call-end-voice-file"} className="mt-3 accordion-voice-item">
                <Accordion.Header>
                    <span className="pmivr-accordian-tab">
                        {title}
                    </span>
                </Accordion.Header>
                <Accordion.Body className="p-3 pt-2" style={{ maxHeight: '460px', overflowY: 'auto' }}>
                    <>
                        <div>
                            <ul className="nav nav-tabs text-center" role="tablist">
                                {
                                    languagesConfigured.map((language) => {
                                        return (
                                            <li className="nav-item  px-2" role="presentation">
                                                <button className={selectedLanguage === language ? "nav-link active " : "nav-link"}
                                                    data-bs-target="#language-options" type="button" role="tab" title={language}
                                                    aria-controls="language-options"
                                                    onClick={() => setSelectedLanguage(language)} style={{ width: '100%' }}>
                                                    {language}</button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="mt-3" id="language-options">
                            <div className="tab-content " id="pills-tabContent">
                                <VoicePromptSelection selectedLanguage={selectedLanguage}
                                    promptsList={voicePromptInfo[selectedLanguage] || []}
                                    onChange={(updatedPromptsList) => onSave(updatedPromptsList, selectedLanguage)}
                                    showAddPromptBtn={showAddPromptBtn} showVariableOption={showVariableOption} />
                            </div>
                        </div>
                    </>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

VoicePromptAccordion.propTypes = {
    // array of voice file prompts for call end, invalid voice file
    voicePromptInfo: PropTypes.arrayOf(
        PropTypes.shape({
            filePath: PropTypes.string,
            ttsText: PropTypes.string,
            playSpeed: PropTypes.number,
            voiceFileType: PropTypes.string,
        })
    ),
    // function to save the call end prompt list 
    onSave: PropTypes.func,
    // title to display on accordion
    title: PropTypes.string,
    // to show add prompt btn or not
    showAddPromptBtn: PropTypes.bool,
    // to disable the variable play in case of speech input we do not have variable play
    showVariableOption: PropTypes.bool
};

VoicePromptAccordion.defaultProps = {
    showAddPromptBtn: true,
    showVariableOption: true
};

export default VoicePromptAccordion;