import { useContext, useEffect, useState } from 'react';
import { SingleVoiceContext } from '../../../contexts/app-context';

/**
 * Edit options for the option user control type
 * @param {Object} props - Props data from the parent component
 * @param {function} props.saveDialogChanges - function to save the changes
 * @returns {React.Component} Html element to render
 */
const EditOptionModal = ({ saveDialogChanges }) => {
  const {
    optionAndVal,
    updateOptionAndValues,
    isOptionAndValStartFromZero,
    removeOption,
    addOption
  } = useContext(SingleVoiceContext);

  // State to track selected options in the select tag
  const [selectedOptions, setSelectedOptions] = useState([]);

  /**
   * Effect to auto-save changes whenever `selectedOptions` or `optionAndVal` changes.
   * It debounces the save operation to prevent excessive calls.
   */
  useEffect(() => {
    debounceSave();
  }, [selectedOptions, optionAndVal]);

  /**
   * useEffect to extracts the initial selected options from `optionAndVal` and updates the `selectedOptions` state with initial values.
   */
  useEffect(() => {
    const initialSelectedOptions = Object.values(optionAndVal)
      .map(([selectedValue]) => parseInt(selectedValue)) // Extract and parse the selected value e.g. [["4", "Value1"], ["6", "Value2"]] --> [4,6]
      .filter((value) => !isNaN(value)); // Filter out invalid or undefined values
    // Updates the state with the extracted and filtered initial options.
    setSelectedOptions(initialSelectedOptions);
  }, [optionAndVal]);

  /**
   * Delays the save function (saveDialogChanges) by 1000ms to prevent immediate saving.
   * Resets the timer if changes continue within the delay period.
   */
  const debounceSave = () => {
    clearTimeout(debounceSave.timeout); // Clear any existing timeout to reset the debounce timer
    // Set a new timeout to delay the call to saveDialogChanges
    debounceSave.timeout = setTimeout(() => {
      saveDialogChanges(); // Call the save function after 1000ms delay
    }, 1000);
  };

  /**
   * Handles the change event of a select input element.
   * @param {Event} event - The event object triggered by the change.
   */
  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    const selectedId = event.target.id;
    // Find the index of the current option in `optionAndVal`
    const optionIndex = parseInt(selectedId.split('_').pop());
    // Retrieve the previously selected value for this index
    const previousValue = parseInt(optionAndVal[optionIndex][0]);
    // Update `selectedOptions` to remove the previous value and add the new value
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = prevSelectedOptions.filter((val) => val !== previousValue);
      if (!updatedOptions.includes(selectedValue)) {
        updatedOptions.push(selectedValue);
      }
      return updatedOptions;
    });

    updateOptionAndValues(event, null);
  };

  // Available options for the select tag is an array initially with numbers 1-9 and updated according to selected options
  const availableOptions = Array(9)
    .fill(null).map((value, indexNum) => {
      const optionValue = isOptionAndValStartFromZero ? indexNum : indexNum + 1;

      if (!selectedOptions.includes(optionValue)) {
        return (
          <option key={indexNum} value={optionValue}>
            {optionValue}
          </option>
        );
      } else {
        return null;
      }
    });

  return (
    <>
      <div className="pt-1">
        <div className="form-group  custom-input">
          {Object.keys(optionAndVal).map((key, index) => {
            return (
              <div className="row pe-2">
                <div className="col-sm-3">
                  <div className="form-group"></div>
                  <div className="props-custom-input">
                    <select
                      required
                      id={`file_option_${index}`}
                      className="pmivr-select"
                      aria-label="Default select example"
                      onChange={(event) => { handleSelectChange(event) }}
                    >
                      <option selected>
                        {optionAndVal[index][0]
                          ? isOptionAndValStartFromZero
                            ? Number(optionAndVal[index][0]) + 1
                            : optionAndVal[index][0]
                          : ""}
                      </option>
                      {availableOptions}
                      <option value="#">#</option>
                    </select>
                    {index === 0 ? <label>Option</label> : <label className='hide-form-label'>Option</label>}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className=" props-custom-input">
                    <input
                      className="form-control pmivr-input"
                      id={`file_value_${index}`}
                      value={optionAndVal[index][1]}
                      onChange={(event) => { updateOptionAndValues(event, event.target.value) }}
                      type="text"
                      placeholder={`${index === 0 ? "" : "Value"}`} // this is required as it will use mentioned label as its value
                    />
                    {index === 0 && <label>Value</label>}
                  </div>
                </div>
                <div className="col-sm-1 text-center mt-1">
                  <i className="bi bi-trash pmivr-btn-remove" id={`file_option_remove_${index}`}
                    onClick={(event) => {
                      removeOption(event);
                      // Remove the selected option from the selectedOptions array
                      setSelectedOptions(selectedOptions?.filter((val) => val !== parseInt(optionAndVal[index][0])));
                    }}
                  ></i>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-center mt-3">
          <button className="pmivr-add-option" onClick={(event) => addOption(event)}>
            Add Options and Values<i className="ms-1">+</i>
          </button>
        </div>
      </div >
    </>
  );
};

export default EditOptionModal;
