import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * Responsible for ivr call logs details and session details
 */
class VoiceFileManagerService {
    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;
    static CLIENT_DATA_URL = `${envConfig.REACT_APP_API_BASE_URL}/clientData`;

    /**
     * Getting the payment types from configuration for the businessCode
     * @param {string} businessCode 
     * @returns {Promise<{paymentTypes}>} Payment types for the given businessCode
     */
    static getMessageConfigByAppCodes(businessCode, appCode) {
        const url = `${this.CLIENT_DATA_URL}/getMessageConfig/search-by-app-Codes?businessCode=${businessCode}&appCode=${appCode}`;
        return appAxios.get(url);
    }

    static updateMessageonfigs(data, businessCode, appCode) {
        const { actionKey, filePath } = data;
        const url = `${this.CLIENT_DATA_URL}/updateMessageConfig?businessCode=${businessCode}`;
        return appAxios.put(url, {
            actionKey: actionKey, actionMessage: filePath, appCode: appCode
        });
    }
}

export default VoiceFileManagerService;