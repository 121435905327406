/**
 * Class to encapsulate filters for fetching logs.
 * This class provides a centralized way to initialize and manage filter parameters,
 * ensuring consistency and ease of use across the application.
 */
class LogsFilter {
    /**
     * Constructor to initialize the LogsFilter object with default or provided values.
     * @param {Object} info - An object containing filter parameters.
     */
    constructor(info = {}) {
        // Initialize filter parameters with default values or provided input values.
        this.sessionId = info.sessionId || ""; // The session identifier for logs.
        this.searchText = info.text || ""; // Text to filter logs based on search input.
        this.stepName = info.stepName || ""; // The name of the step to filter logs.
        this.dnid = info.dnid || ""; // DNID (Dialed Number Identification) for filtering.
        this.callerIdNum = info.callerIdNum || ""; // Caller ID to narrow down logs.
        this.environment = info.environment || ""; // The environment (e.g., dev, prod) to filter logs.
        this.startDate = info.startDate || ""; // The start date for filtering logs.
        this.endDate = info.endDate || ""; // The end date for filtering logs.
        this.count = info.count || ""; // Number of records to fetch in one query.
        this.pageNo = info.pageNo || ""; // Page number for paginated results.
    }
}

export default LogsFilter;