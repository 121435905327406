import Modeler from "bpmn-js/lib/Modeler";
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import bpmnModdleDescriptor from 'bpmn-moddle/resources/bpmn/json/bpmn.json'

import ConfigService from "./config.service";

import CustomModule from '../flow-builder/bpmn/elements';
import VoiceFilesPropertiesProviderModule from '../flow-builder/bpmn/property-provider/voice-files';
import OptionUserInputPropertiesProviderModule from '../flow-builder/bpmn/property-provider/option-input';
import ServiceImplementationPropertiesProviderModule from '../flow-builder/bpmn/property-provider/service-impl';
import UserInputPropertiesProviderModule from '../flow-builder/bpmn/property-provider/user-input';
import SessionPropertiesProviderModule from '../flow-builder/bpmn/property-provider/session';
import CustomCommandInterceptorModule from '../flow-builder/bpmn/CustomCommandInterceptor';
import CustomPaletteProviderModule from '../flow-builder/bpmn/palette-provider';


/**
 * The service class for modeler operations
 */
class ModelerService {

  static _modeler;

  /**
   * 
   * @returns modeler
   */
  static getModeler() {
    return ModelerService._modeler;
  }

  /**
   * Load the flow and required files to create modeler object
   * @param {Object} state 
   */
  static async loadFlowModeler() {
    // get property files
    const propertiesList = ["voice-files", "user-option-input", "user-input", "session-props", "service-impl",
      "user-confirmation", "loop-task", "dynamic-option", "key-value-option", "say-data-task",
      "transfer-task", "condition", "voice-record-start", "voice-record-stop"];
    const arrFilesP$ = ConfigService.getExtFile(propertiesList);
    const responseList = await Promise.all(arrFilesP$);

    const [voice, usrOpt, usrInput, session, serviceImpl, usrConfirmation, loopTask, dynamicOpt, keyValueOpt, sayData,
      transfer, conditionExtension, voiceRecordStart, voiceRecordStop] = responseList;

    ModelerService._modeler = new Modeler({
      container: "#container",
      propertiesPanel: { parent: "#properties" },
      additionalModules: [
        CustomModule,
        propertiesPanelModule,
        propertiesProviderModule,
        bpmnModdleDescriptor,
        VoiceFilesPropertiesProviderModule,
        OptionUserInputPropertiesProviderModule,
        ServiceImplementationPropertiesProviderModule,
        UserInputPropertiesProviderModule,
        SessionPropertiesProviderModule,
        CustomCommandInterceptorModule,
        CustomPaletteProviderModule,
      ],
      moddleExtensions: {
        voice, usrOpt, usrInput, session, serviceImpl, usrConfirmation, loopTask, dynamicOpt, keyValueOpt, sayData,
        transfer, conditionExtension, voiceRecordStart, voiceRecordStop
      },
      keyboard: {
        bindTo: document,
      },
    });
  }
}

export default ModelerService;
