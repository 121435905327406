import { APP_PAGES } from "../../constants/app-pages";

// options available in settings
const SETTING_OPTIONS = [
    {
        "linkTo": APP_PAGES.USERS,
        "iconClass": "bi bi-person-gear",
        "text": "USERS",
        "description": "Manage users and permissions"
    },
    {
        "linkTo": APP_PAGES.NUMBERS,
        "iconClass": "bi bi-file-binary",
        "text": "DNID NUMBERS",
        "description": "Create and view dnid numbers"
    },
    {
        "linkTo": APP_PAGES.TEMPLATES,
        "iconClass": "bi bi-layout-text-window-reverse",
        "text": "FLOW TEMPLATES",
        "description": "Check flow types and upload base flows"
    },
    {
        "linkTo": APP_PAGES.API_KEYS,
        "iconClass": "bi bi-filetype-key",
        "text": "API Keys",
        "description": "Create and view API keys"
    },
    {
        "linkTo": APP_PAGES.CLIENTS,
        "iconClass": "bi bi-receipt",
        "text": "Clients",
        "description": "View the list of onboarded clients"
    },
    {
        "linkTo": APP_PAGES.DRAFT_OPTIONS,
        "iconClass": "bi bi-card-list",
        "text": "Publish & Draft Options",
        "description": "Configure Options"
    },
    {
        "linkTo": APP_PAGES.DEPLOYMENT_ENVIRONMENTS,
        "iconClass": "bi bi-database-gear",
        "text": "Deployment Environments",
        "description": "Configure Deployment Environments"
    },
    {
        "linkTo": APP_PAGES.VOICE_FILE_MANAGER,
        "iconClass": "bi bi-folder2",
        "text": "Voice File Manager",
        "description": "Manage Voice File"
    }
];

export default SETTING_OPTIONS;