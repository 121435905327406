import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../components/common/label/pmivr-label";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { VOICE_FILE_UPLOAD_TYPE, VOICE_GENDER } from "../../../constants/voice-file";
import { CSS_CLASSES } from "../../../constants/css-classes";

import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import AudioPlayer from "../../../components/common/audio-player/audio-player";

import { REGEX } from "../../../config/config";
import AudioUtil from "../../../util/audio.util";
import AudioService from "../../../services/audio.service";

/**
 * Component for handling the text-to-speech functionality.
 * This component allows the user to convert text into speech by selecting a language and 
 * using the provided voice file information. It interacts with a snackbar for feedback 
 * 
 * @param {object} props - The properties passed to the component.
 * @param {function} props.setFilePath - Function to set the file path after the text-to-speech operation.
 * @param {string} props.selectedLanguage - The language selected for generating the speech (e.g., "en", "fr").
 * @param {object} props.voiceFileInfo - Information related to the voice file (e.g., file path, language).
 * 
 * @returns {JSX.Element} The rendered component, which handles text-to-speech conversion.
 */
const TextToSpeech = (props) => {
    const { setFilePath, selectedLanguage, voiceFileInfo } = props;
    const snackbarRef = useRef();
    // Extracting business code from the Redux store
    const { businessCode } = useSelector(state => state.client);
    /**
     * uploadTtsBtnDisable : flag to disable and enable tts upload button
     * showSpeedError : flag to show speed error message
     */
    const [uiState, setUiState] = useState({ uploadTtsBtnDisable: true, showSpeedError: false });
    // State to manage the visibility of the warning message for TTS text.
    const [showTtsTextWarning, setShowTtsTextWarning] = useState(false);
    // State to manage the configuration of the Text - to - Speech(TTS) voice file.
    const [ttsVoiceFileInfo, setTtsVoiceFileInfo] = useState({
        "filePath": "",
        "ttsText": "",
        "selectedGender": VOICE_GENDER.MALE || "",
        "playSpeed": 80 || ""
    });

    /**
     * Handles TTS gender selection (male/female)
     * @param {Event} event 
     */
    const genderRadioBtnHandler = (event) => {
        // enable the upload button on gender change
        setUiState({ ...uiState, uploadTtsBtnDisable: false });
        // update the gender value in voice file info
        setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, selectedGender: event?.target?.value });
    }

    /**
     * Handles TTS voice speed (10-100)
     * @param {Event} event 
     */
    const setVoiceSpeed = (event) => {
        // enable the upload button on speed change
        setUiState({ ...uiState, showSpeedError: false, uploadTtsBtnDisable: false });
        // update the playSpeed of voice file in voice file info
        setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, playSpeed: event?.target?.value });
    }

    /**
     * Uploads the tts file to server
     */
    const uploadTts = async () => {
        try {
            // check if entered the valid speed or not
            if (ttsVoiceFileInfo?.playSpeed < 10 || ttsVoiceFileInfo.playSpeed > 100) {
                setUiState({ ...uiState, showSpeedError: true });
                return;
            }
            setUiState({ ...uiState, uploadTtsBtnDisable: true });
            const ttsVoiceIdInfo = AudioUtil.getTtsVoiceId(selectedLanguage, ttsVoiceFileInfo.selectedGender);
            const response = await AudioService.uploadVoiceFileToRepo({
                text: ttsVoiceFileInfo.ttsText, language: "en",
                voiceId: ttsVoiceIdInfo?.voiceId, languageCode: ttsVoiceIdInfo?.languageCode, speedRate: ttsVoiceFileInfo.playSpeed, businessCode: businessCode, folderName: "voice-file-manager"
            });

            if (response?.data?.filePath) {
                const newFilePath = response.data.filePath.replace(/^voice-files\//, '').replace(/\.wav$/, '');
                const newInfo = {
                    filePath: newFilePath,
                    voiceFileType: VOICE_FILE_UPLOAD_TYPE.TTS,
                    ttsText: ttsVoiceFileInfo.ttsText,
                    selectedGender: ttsVoiceFileInfo.selectedGender,
                    speedRate: ttsVoiceFileInfo.playSpeed
                }
                const newVoiceFileInfo = _updateVoiceFileInfo(voiceFileInfo, selectedLanguage, newInfo);
                setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, filePath: newFilePath });
                setFilePath(response?.data.filePath);
                // get the info of the voice file attribute
                snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
            }
            setUiState({ ...uiState, uploadTtsBtnDisable: true });
        } catch (err) {
            setUiState({ ...uiState, uploadTtsBtnDisable: false });
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
            }
        }
    }

    /**
     * Updates the voice file info object with new details
     * @param {Object} oldInfo - old details of the voice file
     * @param {string} language - language of the voice file to be updated
     * @param {Object} newInfo - new details of the voice file 
     * @returns {Object} full updated voiceFileInfo 
     */
    function _updateVoiceFileInfo(oldInfo, language, newInfo) {
        // Return a new object with the updated language info
        return {
            ...oldInfo,
            language: {
                ...oldInfo.language,
                [language]: {
                    ...oldInfo.language[language],
                    ...newInfo
                }
            }
        };
    }

    return (
        <div>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="d-flex justify-content-between">
                <div className="pmivr-title pt-2">Text To Speech</div>
                <AudioPlayer filePath="" cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
                    isUploadedOnGit={true}></AudioPlayer>
            </div>
            <div className="px-0">
                <div className="form-group mb-2 pmivr-relative">
                    <PmivrLabel label="Type Message" tooltip={TOOLTIP.INFO.TTS} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS}>
                        <textarea id="greetingAudioMessage" className="form-control pmivr-input"
                            onChange={(event) => {
                                const inputValue = event.target.value;
                                const ttsRegex = REGEX.TTS_TEXT;
                                if (!ttsRegex.test(inputValue)) {
                                    setShowTtsTextWarning(true);
                                    setUiState({ ...uiState, uploadTtsBtnDisable: true });
                                } else {
                                    setShowTtsTextWarning(false);
                                    setUiState({ ...uiState, uploadTtsBtnDisable: false });
                                }
                                const tempInfo = ttsVoiceFileInfo;
                                tempInfo.ttsText = inputValue;
                                setTtsVoiceFileInfo(tempInfo);
                            }}
                        />
                        {showTtsTextWarning && <div className='field-error'>{MESSAGES.TTS_TEXT_WARNING}</div>}
                    </PmivrOverlayTrigger>
                </div>
                <div className="row px-2">
                    <div className="col-sm-7 p-1">
                        <label>Gender:</label>
                        <div className="mt-2">
                            <label className="pmivr-check-radio me-3">
                                <input type="radio" name="gender" value="male" className="me-1"
                                    checked={ttsVoiceFileInfo.selectedGender === VOICE_GENDER.MALE}
                                    onChange={(e) => genderRadioBtnHandler(e)}
                                />
                                Male
                            </label>
                            <label className="pmivr-check-radio">
                                <input type="radio" name="gender" value="female" className="me-1"
                                    checked={ttsVoiceFileInfo.selectedGender === VOICE_GENDER.FEMALE} onChange={(e) => genderRadioBtnHandler(e)} />
                                Female
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-5 p-1">
                        <div className="form-group">
                            <PmivrLabel label="Speed" tooltip={TOOLTIP.INPUT.TTS_SPEED} cssClass={`mt-0 mb-1`} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS_SPEED}>
                                <input type="number" className="flow-control pmivr-input" aria-label="Default input example"
                                    value={ttsVoiceFileInfo.playSpeed} onChange={(e) => setVoiceSpeed(e)} min={10} max={100} />
                            </PmivrOverlayTrigger>
                        </div>
                    </div>
                    {uiState?.showSpeedError && <div className='field-error'>{MESSAGES.TTS_SPEED_WARNING}</div>}
                    <button className="pmivr-btn-secondary mt-3 p-3" disabled={uiState.uploadTtsBtnDisable || !ttsVoiceFileInfo.ttsText}
                        onClick={() => uploadTts()}>
                        Generate Speech File
                    </button>
                </div>
            </div>
        </div>
    );
};
export default TextToSpeech;
