import { useContext, useEffect, useState } from "react";

import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";

import { ATTRIBUTES } from "../../../constants/attributes";
import { TASK_TYPE } from "../../../constants/task-types";
import { TOOLTIP } from "../../../constants/messages";
import { TASK_ICONS } from "../../../constants/css-classes";

import ElementService from "../../../services/element.service";

import { VoiceContext } from "../../../contexts/app-context";

/**
 * Enable/Disable speech recognition option, On enabled, grammar (numeric/digit etc) can be selected.
 * Will introduce attributes like speechInputGrammar on the passed element so that AGI can respond accordingly
 * @returns {React.Component} render speech input option
 */
const SpeechInput = () => {
  // selected grammar from dropdown
  const [selectedGrammar, setSelectedGrammar] = useState("");
  // is speech input enabled / diabled
  const [isSpeechInput, setIsSpeechInput] = useState(false);
  // flag to allow user to select the grammar for speech input
  const [allowSelectGrammar, setAllowSelectGrammar] = useState(false);

  // select options for grammar speech
  // ASR_AUTH_GRAMMAR: grammar for authorize payment auth (accept authorize speech input)
  const grammarOptions = [
    { text: "Select Grammar", value: "", attribute: "disabled" },
    { text: "Digit", value: "ASR_DIGIT_GRAMMAR" },
    { text: "Alpha Numeric", value: "ASR_ALPHA_NUM_GRAMMAR" },
    { text: "Currency", value: "ASR_CURRENCY_GRAMMAR" },
    { text: "Authorize", value: "ASR_AUTH_GRAMMAR", attribute: "selected" }
  ];

  // useContext provider VoiceContext provides the value from parent
  const { element } = useContext(VoiceContext);

  useEffect(() => {
    const init = () => {
      const taskType = element.businessObject.taskType;
      let speechInputEnabled = false;
      switch (taskType) {
        case TASK_TYPE.promptUserOption:
        case TASK_TYPE.keyValueUserOption:
        case TASK_TYPE.dynamicUserOption:
          speechInputEnabled = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, false);
          // if user option node, then we know it will be option grammar by default. No need to allow to select grammar
          setAllowSelectGrammar(false);
          setSelectedGrammar(ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR, ""));
          setIsSpeechInput(speechInputEnabled);
          break;
        case TASK_TYPE.promptUserInput:
          setAllowSelectGrammar(true);
          speechInputEnabled = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_IS_SPEECH_INPUT, false);
          setSelectedGrammar(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, ""));
          setIsSpeechInput(speechInputEnabled);
          break;
        default:
          break;
      }
    }
    init();
    // on change of element state should also get updated
  }, [element]);

  /**
   * updating the modeler with ASR speech input enable flag.
   * If value is false (checkbox is unchecked), then reseting the value of ASR grammar input value as empty
   * @param {Object} e event object of checkbox
   */
  const allowSpeechInput = (e) => {
    const isEnabled = e.target.checked;
    const taskType = element.businessObject.taskType;

    switch (taskType) {
      case TASK_TYPE.promptUserOption:
      case TASK_TYPE.keyValueUserOption:
      case TASK_TYPE.dynamicUserOption:
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, isEnabled);
        // if user option node, then we know it will be option grammar by default
        const grammar = (isEnabled) ? "ASR_OPTIONS_GRAMMAR" : "";
        ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR, grammar);
        break;
      case TASK_TYPE.promptUserInput:
        // updating the selected element in modeler
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_IS_SPEECH_INPUT, isEnabled);
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, "");
        break;
      default:
      // do nothing
    }
    // updating the state
    setIsSpeechInput(isEnabled);
    if (isEnabled) {
      // Automatically select the option with attribute === "selected"
      const selectedOption = grammarOptions.find(option => option.attribute === "selected");
      if (selectedOption) {
        setSelectedGrammar(selectedOption.value);
      }
    } else {
      setSelectedGrammar(""); // reset grammar when speech input is disabled
    }
  }

  /**
   * updating the modeler and state with ASR grammar input value
   * @param {Object} event event object of select box of ASR Input
   */
  const updateGrammar = (event) => {
    const value = event.target.value;
    setSelectedGrammar(value); // updating the state
    if (element.businessObject.taskType === TASK_TYPE.promptUserInput) {
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, value); // updating the modeler
    }
  }

  return (
    <>
      <div className="form-check pmivr-check-radio form-check-inline mb-2 check-box-label-align">
        <input className="form-check-input" type="checkbox" id="is-speech-input" checked={isSpeechInput}
          onChange={(e) => { allowSpeechInput(e); }} />
        <label className="form-check-label">
          Enable Speech Input
        </label>
        <PmivrTooltip message={TOOLTIP.INFO.IS_SPEECH_INPUT}>
          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
        </PmivrTooltip>
      </div>
      <div className={allowSelectGrammar ? "" : "pmivr-hide-display"}>
        <div className="pmivr-label">
          <label className={selectedGrammar ? "pmivr-label pb-2" : "pmivr-hide-display"}>Select Grammar</label>
        </div>
        <select id="selected-grammar" className="pmivr-select mb-3" disabled={!isSpeechInput}
          onChange={(e) => { updateGrammar(e); }} value={selectedGrammar}>
          {grammarOptions.map((option) => {
            return (
              <option key={option.value} value={option.value} disabled={option?.attribute === "disabled"}>
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default SpeechInput;