// type of events for voice control type
export const EVENT_TYPE = {
  VOICE_FILE_UPLOAD: "VOICE_FILE_UPLOAD",
  VOICE_TTS: "VOICE_TTS",
  VOICE_PATH: "VOICE_FILE_PATH"
};

// modeler event to listen
export const MODELER_EVENTS = {
  UPDATE_PROPERTY: "commandStack.element.updateProperties.postExecute",
  UPDATE_NAME: "commandStack.element.updateLabel.executed",
  CREATE_SHAPE: "commandStack.shape.create.executed",
  ELEMENT_CHANGED: "element.changed",
  ELEMENT_MOVED: "commandStack.elements.move.postExecuted",
  ELEMENT_DELETE: "commandStack.elements.delete.postExecute",
  UNDO_ELEMENT_DELETE: "commandStack.shape.delete.reverted",
  SELECTION_CHANGED: "selection.changed",
  CONNECTION_DELETE: "commandStack.connection.delete.preExecute",
  UNDO_CONNECTION_DELETE: "commandStack.connection.delete.reverted",
  CONNECTION_CREATED: "commandStack.connection.create.executed",
  CONTEXT_PAD_OPEN: "contextPad.open",
  CUSTOM: {
    CREATE_SHAPE_AND_RENAME: "elementCreatedAndRenamed",
    ELEMENT_DISABLE_TOGGLED: "elementDisableToggled"
  }
}