import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { APP_PAGES } from '../../constants/app-pages';

import VoiceFileList from './components/voice-file-list';
import ManageVoiceFile from './components/manage-voice-files';

import VoiceFileManagerService from '../../services/voice-file-manager.service';

/**
 * VoiceFileManager Component
 * 
 * This component manages voice file settings, including fetching, displaying, 
 * and updating voice file details. It uses various states to manage the UI and data.
 */
const VoiceFileManager = () => {
    // Extracting business code from the Redux store
    const { businessCode, appCode } = useSelector(state => state.client);
    // State to manage the visibility of the voice file settings menu and store the list of voice files.
    const [uiState, setUiState] = useState({ showVoiceFileSettingMenu: false, voiceFiles: [] });
    // State to store the title of the current voice file action.
    const [voiceFileActionTitle, setVoiceFileActionTitle] = useState("");
    // State to store details about the selected action and file.
    const [selectedDetails, setSelectedDetails] = useState({ actionKey: '', filePath: '' });
    // State to track the current action key for the selected voice file.
    const [actionKey, setActionKey] = useState(selectedDetails.actionKey || '');
    // State to track the file path of the selected voice file.
    const [filePath, setFilePath] = useState(selectedDetails.filePath || '');
    // State to store the list of all available voice files.
    const [voiceFileList, setVoiceFilList] = useState([]);
    // Information about the system voice file (file path,language)
    const [voiceFileInfo, setVoiceFileInfo] = useState({
        filePath: "",
        ttsText: "",
        selectedGender: "",
        playSpeed: "",
        fileSize: "",
        voiceFileType: ""
    });

    /**
     * Effect to fetch voice file details when the component is mounted.
     */
    useEffect(() => {
        getVoiceFileDetails();
    }, []);

    /**
     * Fetches voice file details from the server using the VoiceFileManagerService.
     * Updates the state with the retrieved list of voice files.
     * 
     * @async
     * @function getVoiceFileDetails
     * @returns {Promise<void>}
     */
    const getVoiceFileDetails = async () => {
        const response = await VoiceFileManagerService.getMessageConfigByAppCodes(businessCode, appCode);
        setVoiceFilList(response);
    }

    /**
     * Handles saving the selected voice file details by updating the configuration
     * through the VoiceFileManagerService and resetting the selected details if successful.
     * This function updates the action key and file path, triggers a server update, 
     * and fetches the latest voice file details after a successful update.
     */
    const handleSave = async () => {
        // Set the selected action key and file path to be saved
        setSelectedDetails({ actionKey: actionKey, filePath: filePath });
        // Update the voice file configurations on the server
        const response = await VoiceFileManagerService.updateMessageonfigs({ actionKey: actionKey, filePath: filePath }, businessCode, "inbound-agi-server");
        // If the response status is OK (200), fetch the latest voice file details and reset selection
        if (response?.statusCode === 200) {
            getVoiceFileDetails();
            setSelectedDetails({ actionKey: "", filePath: "" });
        }
    }

    /**
     * Handles the UI state for showing or hiding the voice file settings menu.
     * This function updates the UI state to show or hide the menu based on the 
     * provided `showSetting` boolean value.
     * @function handleUiState
     * @param {boolean} showSetting - A boolean value to indicate whether to show the settings menu or not.
     * @returns {void}
     */
    const handleUiState = (showSetting) => {
        setUiState({ ...uiState, showVoiceFileSettingMenu: showSetting });
    }

    return (
        <>
            <div className="row pt-1">
                <div className="row border-bottom pb-3 pt-3 ">
                    <div className="col-lg-6">
                        <div className="px-3 pmivr-breadcrumb-list">
                            <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link>  / Voice File Manager
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div div className={`col-md-${uiState.showVoiceFileSettingMenu ? '8' : '12'} pt-2`} >
                    <div className='row '>
                        <div className="col-md-6 pmivr-breadcrumb-list pb-2">
                            <h4> <Link to={APP_PAGES.SETTINGS}><i class="bi bi-arrow-left arrow-left"></i></Link>  Voice File Manager</h4>
                        </div>
                        <div className='col-md-6 d-flex justify-content-end pe-3'>
                            <button className='pmivr-btn-secondary me-2'>Publish On UAT</button>
                            <button className='pmivr-btn-app'>Publish On PROD</button>
                        </div>
                    </div>
                    <VoiceFileList setUiState={setUiState} uiState={uiState} handleUiState={handleUiState} setVoiceFileActionTitle={setVoiceFileActionTitle}
                        setSelectedDetails={setSelectedDetails} voiceFileList={voiceFileList} setFilePath={setFilePath} setVoiceFileInfo={setVoiceFileInfo} />
                </div>
                {uiState.showVoiceFileSettingMenu &&
                    <div className={`col-md-4 border-start ps-0`}>
                        <ManageVoiceFile voiceFileActionTitle={voiceFileActionTitle} selectedDetails={selectedDetails}
                            setSelectedDetails={setSelectedDetails} actionKey={actionKey} setActionKey={setActionKey}
                            filePath={filePath} setFilePath={setFilePath} voiceFileInfo={voiceFileInfo} />
                        <div className='d-flex justify-content-end me-3'>
                            <button type="button" className="pmivr-btn-secondary me-1" onClick={() => handleUiState(false)}>
                                Cancel
                            </button>
                            <button type="submit" className="pmivr-btn-app me-1" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                }
            </div >
        </>
    );
}

export default VoiceFileManager;
