import React from "react";
import { Accordion } from "react-bootstrap";
import AppUtil from "../../../util/app.util";
import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";


/**
 * Component to render a list of call logs with filtering, searching, and grouping functionality.
 * Includes an accordion for detailed log information.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.filterCallLogs - Filter parameters for the call logs.
 * @param {Function} props.handleInputChange - Function to handle input changes for filters.
 * @param {Function} props.loadCallLogsGroup - Function to load grouped call logs.
 * @param {Function} props.loadCallLogs - Function to load call logs.
 * @param {Object} props.uiState - UI state management object.
 * @param {Function} props.setUiState - Function to update the UI state.
 * @param {Function} props.handleScroll - Function to handle scrolling in the call logs table.
 * @param {Array} props.callLogGroup - Array of grouped call logs.
 * @param {Function} props.handleLogsListScroll - Function to handle scrolling in the detailed logs list.
 * @param {Array} props.callLogs - Array of individual call logs.
 * @param {Function} props.setLogDetailsDialogProps - Function to set properties for the log details dialog.
 */
const CallLogsList = (props) => {
    const { filterCallLogs, handleInputChange, loadCallLogsGroup, loadCallLogs, uiState, setUiState, handleScroll, callLogGroup, handleLogsListScroll, callLogs, setLogDetailsDialogProps } = props;
    return (
        <div className="m-2 mt-0 mb-0 py-2 bg-white rounded shadow-sm">
            <div className="row d-flex align-items-center px-2 justify-content-between">
                <div className="col">
                    <h5 className="">Call Logs</h5>
                </div>
                <div className="col-3 d-flex align-items-center pmivr-relative">
                    <input type="text" id="searchBox" className="form-control pmivr-input" value={filterCallLogs.text}
                        placeholder="Search text" onChange={(e) => handleInputChange('text', e.target.value)}
                        onKeyDown={(e) => {
                            if (AppUtil.isEnterKey(e) || e.target.value.trim() === "") {
                                loadCallLogsGroup(1, 'reset');
                                loadCallLogs({ pageNo: 1, action: "reset" });
                            }
                        }} />
                    <span className="btn-search-key pe-3">
                        <button
                            disabled={!filterCallLogs.text}
                            className="pmivr-btn-transparent"
                            onClick={(e) => {
                                if (AppUtil.isEnterKey(e) || e.target.value.trim() === "") {
                                    loadCallLogsGroup(1, 'reset');
                                    loadCallLogs({ pageNo: 1, action: "reset" });
                                }
                            }}
                        >
                            <i className="bi bi-search"></i>
                        </button>
                    </span>
                </div>
                <div className="col-auto">
                    <PmivrOverlayTrigger tooltip="Advance Search">
                        <i class="bi bi-sliders fs-5 cursor-pointer pe-1"
                            onClick={() => { setUiState({ showFilterDialog: !uiState?.showFilterDialog }) }}></i>
                    </PmivrOverlayTrigger>
                </div>
            </div>
            {/* list of call logs table */}
            <div className="row mx-2">
                <table className="table pmivr-table pmivr-accordian-table table-sm header-fixed border mt-2 mb-0">
                    <thead>
                        <tr>
                            <th width="10%" className="text-center">Timestamp</th>
                            <th width="10%" className="text-center">DNID</th>
                            <th width="10%" className="text-center">Lext</th>
                            <th width="10%" className="text-center">Session ID</th>
                            <th width="10%" className="text-center">Caller Id Number</th>
                            <th width="10%" className="text-center">Env</th>
                            <th width="10%" className="text-center">Business Code / TLA</th>
                            <th width="20%" className="text-center">Step Name</th>
                            <th width="5px" className="float end"></th>
                        </tr>
                    </thead>
                    <tbody className="pmivr-scroll" onScroll={handleScroll}>
                        <Accordion defaultActiveKey="0" flush>
                            {(callLogGroup?.length) ?
                                callLogGroup?.map((callLog, index) => {
                                    return (
                                        <tr key={index} className="cursor-pointer" onClick={() => { loadCallLogs({ pageNo: 1, sessionId: callLog?.sessionId, action: "reset" }) }}>
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header className="main-table-content">
                                                    <td width="10%" title={callLog.startTime} className="text-center pt-2">{AppUtil.formatDateInLocal(callLog.startTime)}</td>
                                                    <td width="10%" title={callLog.dnid} className="text-center pt-2">{callLog.dnid}</td>
                                                    <td width="10%" title={callLog.lext} className="text-center pt-2">{callLog.lext || '-'}</td>
                                                    <td width="10%" title={callLog.sessionId} className="text-center pt-2">{callLog.sessionId}</td>
                                                    <td width="10%" title={callLog.callerIdNum} className="text-center pt-2">{callLog.callerIdNum}</td>
                                                    <td width="10%" title={callLog.environment} className="text-center pt-2">{callLog.environment || '-'}</td>
                                                    <td width="10%" title={callLog.businessCode} className="text-center pt-2">{callLog.businessCode}</td>
                                                    <td width="20%" title={callLog.stepName} className="text-center pt-2">{callLog.stepName || '-'}</td>
                                                    <td width="25px">{""}</td>
                                                </Accordion.Header>
                                                <Accordion.Body className="pb-0">
                                                    <table className="table pmivr-table pmivr-accordian-table table-sm header-fixed border" onClick={(e) => e.stopPropagation()}>
                                                        <thead>
                                                            <tr>
                                                                <th width="20%" className="text-center">Timestamp</th>
                                                                <th width="10%" className="text-center">DNID</th>
                                                                <th width="10%" className="text-center">Lext</th>
                                                                <th width="10%" className="text-center">Caller Id Number</th>
                                                                <th width="10%" className="text-center">Env</th>
                                                                <th width="10%" className="text-center">Business Code / TLA</th>
                                                                <th width="30%" className="text-center">Step Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody onScroll={handleLogsListScroll}>{
                                                            callLogs?.map((log, index) => (
                                                                <tr key={index} onClick={() => setLogDetailsDialogProps({ showLogInfoDialog: true, currentLog: log })}
                                                                    className="cursor-pointer">
                                                                    <td width="20%" title={log?.startTime} className="text-center">{AppUtil.formatDateInLocal(log.startTime)}</td>
                                                                    <td width="10%" title={log?.dnid} className="text-center">{log?.dnid}</td>
                                                                    <td width="10%" title={log?.lext} className="text-center">{log?.lext || '-'}</td>
                                                                    <td width="10%" title={log?.callerIdNum} className="text-center">{log?.callerIdNum}</td>
                                                                    <td width="10%" title={log?.environment} className="text-center">{log?.environment || '-'}</td>
                                                                    <td width="10%" title={log?.businessCode} className="text-center">{log?.businessCode}</td>
                                                                    <td width="30%" title={log?.stepName} className="text-center">{log?.stepName || '-'}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </tr>
                                    )
                                }) : <tr><td>No Call Logs</td></tr>
                            }
                        </Accordion>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CallLogsList;