import React from 'react'

import PmivrTooltip, { TOOLTIP_POSITIONS } from '../../../components/common/tooltip/pmivr-tooltip';

import { TOOLTIP } from '../../../constants/messages';
import PmivrSearchInput from '../../../components/common/search-input/pmivr-search';

const VoiceFileList = (props) => {
    const { handleUiState, setUiState, uiState, setVoiceFileActionTitle, setSelectedDetails, voiceFileList, setFilePath, setVoiceFileInfo } = props;

    /**
     * Handles the display of the voice file settings menu when a specific file is selected.
     * This function updates the file path in the state, sets the voice file information,
     * and shows the voice file settings menu.
     * 
     * @function handleVoiceFileSettingMenu
     * @param {Event} event - The event object representing the user interaction (e.g., a click event).
     * @param {string} filePath - The file path of the selected voice file.
     * @returns {Promise<void>} - A promise that resolves once the UI state is updated and the menu is shown.
     */
    const handleVoiceFileSettingMenu = async (event, filePath) => {
        // Prevent the event from propagating to parent elements
        event.stopPropagation();
        setFilePath(filePath);
        setVoiceFileInfo({ filePath: filePath });
        // Show the voice file setting menu
        setUiState({ ...uiState, showVoiceFileSettingMenu: true });
    };
    /**
  * extract the voice file information from the system voice files array
  * @param {string} fileName key value identifier to fetch voice files
  * @returns {{fileName,language}} selected voice file info object
  */
    // const _getVoiceFileInfo = (fileName) => {
    //     const file = uiState?.systemVoiceFiles.find((file) => file.fileName === fileName);
    //     return file ? file : null;
    // };

    /**
     * Handles the edit action when the user clicks on an item in the voice file list.
     * It updates the selected details (action key and file path) to be used in editing the voice file.
     *
     * @function handleEditClick
     * @param {string} key - The action key associated with the selected voice file.
     * @param {string} filePath - The file path of the voice file to be edited.
     * @returns {void}
     */
    const handleEditClick = (key, filePath) => {
        setSelectedDetails({ actionKey: key, filePath });
    };

    /**
     * Handles the search functionality for voice files by capturing the input value
     * and logging it for debugging purposes. This can be further used to filter the voice files.
     *
     * @function searchVoiceFiles
     * @param {Event} event - The event object triggered by the search input field.
     * @returns {void}
     */
    const searchVoiceFiles = (event) => {
        console.log("event:", event?.target?.value);
    }

    return (
        <div className='ps-2 pe-2 pe-0'>
            <div className='col-4'>
                <PmivrSearchInput
                    placeholder="Search business code / tla"
                    onSearch={(searchValue) => searchVoiceFiles(searchValue)}
                    onEnter={searchVoiceFiles}
                    label="Search Business Code / TLA"
                    tooltip="Search Business Code / TLA"
                />
            </div>
            <div className="pt-2">
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='col-3'>
                        <h5 className='pt-2'>Voice File Lists</h5>
                    </div>
                    <div className='col-auto'>
                        <button className='btn pmivr-btn-app' onClick={() => { handleUiState(true); handleEditClick("", ""); setVoiceFileActionTitle("Add Voice File") }}>
                            + Add new
                        </button>
                    </div>
                </div>
                <table className="table pmivr-table header-fixed border mt-2 voice-file-manager-table" id="main-table">
                    <thead className='border-bottom'>
                        <tr>
                            <th width="5%" scope="col">#</th>
                            <th width="20%" scope="col" className="text-center">Action key</th>
                            <th width="45%" scope="col" className="text-center">Value</th>
                            <th width="10%" scope="col" className="text-center">Language</th>
                            <th width="10%" scope="col" className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="pmivr-scroll" style={{ borderStyle: "none", height: "350px" }}>
                        {voiceFileList && Object.keys(voiceFileList).length > 0 ? (
                            Object.keys(voiceFileList).map((key, index) => (
                                <tr key={key}>
                                    <th width="5%" scope="row">{index + 1}</th>
                                    <td width="20%" className="text-truncate cursor-pointer">
                                        {key}
                                    </td>
                                    <td width="45%" className="text-truncate cursor-pointer" style={{ maxWidth: '200px' }}>
                                        <PmivrTooltip message="/home/voxusr/inbound-agi-sounds/voice-files/es/welcome" position={TOOLTIP_POSITIONS.TOP}>
                                            <span>{voiceFileList[key]}</span>
                                        </PmivrTooltip>
                                    </td>
                                    <td width="10%">English</td>
                                    <td width="10%">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message="Edit" position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-pencil-square fa-lg icon-font-size play-button"
                                                        onClick={(e) => { handleUiState(true); handleEditClick(key, voiceFileList[key]); handleVoiceFileSettingMenu(e, voiceFileList[key]); setVoiceFileActionTitle("Edit Voice File") }}></i>
                                                </PmivrTooltip>
                                            </span>
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message={TOOLTIP.DOWNLOAD_FILE} position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-cloud-arrow-down fa-lg icon-font-size play-button fs-4 align-middle"></i>
                                                </PmivrTooltip>
                                            </span>
                                            <span className='pe-2 cursor-pointer'>
                                                <PmivrTooltip message={TOOLTIP.DELETE} position={TOOLTIP_POSITIONS.TOP}>
                                                    <i className="bi bi-trash fa-lg  icon-font-size play-button"></i>
                                                </PmivrTooltip>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr><td>No Call Logs</td></tr>
                        )}
                    </tbody>
                </table>
            </div >
        </div >
    )
}

export default VoiceFileList;