import { forwardRef, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { CSS_CLASSES } from "../../../constants/css-classes.js";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import PmivrSnackbar from "../../common/dialog/pmivr-snackbar";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import AudioPlayer from "../../common/audio-player/audio-player.js";

import { decode as decodeWav } from 'wav-decoder';

import FlowService from "../../../services/flow.service.js";
import AudioService from "../../../services/audio.service.js";

/**
 * The upload voice file option view in the diagram
 */
const UploadVoiceFileOption = forwardRef((props, ref) => {

  // file should be MONO
  const CHANNELS = { MONO: 1 };
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // set the file uploade
  const [file, setFile] = useState(null);
  // set the file path
  const [filePath, setFilePath] = useState();
  // set file size and display
  const [fileSize, setFileSize] = useState();
  const [isUploadedOnGit, setIsUploadedOnGit] = useState(false);
  // info message displayed if file succesfully uploaded
  const [infoMessage, setInfoMessage] = useState("");
  // error message in case of any faliure
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [uiState, setUiState] = useState({ uploadBtnDisable: true });

  const { voiceFileInfo, selectedLanguage, onChange, pathInfo } = props;

  useEffect(() => {
    const init = () => {
      setInfoMessage("");
      setIsErrorMessage(false);
      const voiceFileDetails = voiceFileInfo[selectedLanguage];
      setFileSize(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.fileSize : "" : "");
      setFilePath(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.filePath : "" : "");
      setIsUploadedOnGit(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails?.isUploadedOnGit : false : false);
    }
    init();
  }, [voiceFileInfo]);

  /**
   * Update the uplod voice file changes
   * @param {Object} event event of type object
   */
  const voiceFileUploadChangeHandler = async (event) => {
    try {
      // Check if the file extension is .wav
      if (event?.target?.files?.length && event?.target?.files[0].name.endsWith(".wav")) {
        const file = event.target.files[0];
        // read the file that is uploaded
        const reader = new FileReader();
        // runs when file reading is complete
        reader.onload = async (event) => {
          // get the buffer from file
          const arrayBuffer = event.target.result;
          // Decode the WAV audio data from the buffer
          const audioData = await decodeWav(arrayBuffer);

          // Check if wav file is valid or not
          if (!isWavFileValid(audioData)) {
            setInfoMessage(`Unsupported File: ${file.name}`);
            setIsErrorMessage(true);
            setFile(null);
          } else {
            setFile(file);
            setFilePath(file.name);
            setInfoMessage(`File Added Successfully`);
            setFileSize(Math.floor((file.size) / 1000));
            setIsErrorMessage(false);
          }
        };
        // Read the file as an array buffer
        reader.readAsArrayBuffer(file);
      } else {
        snackbarRef.current.open(MESSAGES.VOICE_FILE_UPLOAD_CORRECT_FORMAT);
      }
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.VOICE_FILE_UPLOAD);
      }
    }
  };

  /**
   * Check if the wav file is valid or not
   * @param {Object} audioData data of the audio file
   * @returns {boolean} valid file or not
   */
  const isWavFileValid = (audioData) => {
    if (audioData.sampleRate !== 8000 || audioData.numberOfChannels !== CHANNELS.MONO) {
      return false;
    }
    return true;
  }

  /**
   * Uploads the audio file
   */
  const uploadFile = async () => {
    try {
      // basic flow for for uploading file , like busniessCode
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      // basic flowInfo for tts
      const flowInfo = {
        businessCode: pathInfo?.businessCode || basicFlowInfo?.businessCode,
        language: selectedLanguage, flowName: pathInfo?.dirName || basicFlowInfo?.flowName
      };
      // necessary fields in case of upload
      flowInfo.file = file;
      const response = await AudioService.uploadVoiceFile(flowInfo);
      if (response) {
        onChange({
          filePath: response?.data?.metaInfo?.location, isUploadedOnGit: response?.data?.metaInfo?.isUploadedOnGit,
          fileSize
        });
        snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
        setUiState({ ...uiState, uploadBtnDisable: true });
      }
    } catch (err) {
      setUiState({ ...uiState, uploadBtnDisable: false });
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
      }
    }
  }

  return (
    <>
      <PmivrSnackbar ref={snackbarRef} />
      <div className="d-flex justify-content-between">
        <div className="pmivr-title pt-2">Upload Audio File</div>
        <AudioPlayer filePath={filePath}
          cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
          isUploadedOnGit={isUploadedOnGit}></AudioPlayer>
      </div>
      <div className="pmivr-drop-audio-file">
        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
          <div className="choose-file">
            Drop audio file here or{" "}
            <a href="/#">Browse <i className="bi bi-link-45deg"></i></a>
            <input
              id="voiceFileUpload"
              className="form-control form-control-lg"
              accept=".wav"
              onChange={(e) => { voiceFileUploadChangeHandler(e); setUiState({ ...uiState, uploadBtnDisable: false }); }}
              placeholder="Drop audio file here or Browse"
              type="file"
            />
          </div>
        </PmivrOverlayTrigger>
      </div>
      <div className="pmivr-upload-file">
        <div className={isErrorMessage ? "failed-upload" : "success-upload"}>{infoMessage}</div>
        <div className="file-name mb-2">File Path : <span className="file-path">{filePath}</span></div>
        <div className="file-size">File Size : <span className="file-path"> {fileSize} {fileSize !== "" ? "KB" : ""}</span></div>
      </div>
      <button
        style={{ width: "-webkit-fill-available" }}
        className="pmivr-btn-secondary mt-3 p-3"
        disabled={uiState.uploadBtnDisable || !file}
        onClick={() => uploadFile()}>Upload File</button>
    </>
  );
});

UploadVoiceFileOption.propTypes = {
  // voice file info object
  voiceFileInfo: PropTypes.object,
  // nam eof the selected language
  selectedLanguage: PropTypes.string,
  // onChange function to update tts values in prompts list
  onChange: PropTypes.func,
  pathInfo: PropTypes.object
}

export default UploadVoiceFileOption;