import { forwardRef, useImperativeHandle } from 'react';

import { Formik, Form, Field, ErrorMessage } from "formik";
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { REGEX } from "../../../config/config";

import PmivrLabel from "../../../components/common/label/pmivr-label";

import AppUtil from "../../../util/app.util";
import DateUtil from "../../../util/date.util";

/**
 * Component to select range of date and time, used to filter data on basis of date-time range
 * @param {{Function}} onChange Function which is called when date-time range is changed or enter key is pressed
 * @param {React.Ref} ref - A forwarded ref that can be used to reference the functions. 
 * @returns {JSX.Element} A date and time range picker form with fields for selecting start and end date-time range.
 */
const PmivrDateTimeRangePicker = forwardRef(({ onChange }, ref) => {
    // Validation schema for Formik using Yup
    const validationSchema = Yup.object().shape({
        startDate: Yup.string()
            .matches(REGEX.DATE_TIME, `Start Date must be in the format ${MESSAGES.DATE_TIME_FORMAT}`),
        endDate: Yup.string()
            .matches(REGEX.DATE_TIME, `End Date must be in the format ${MESSAGES.DATE_TIME_FORMAT}`)
            .test('isAfterStart', 'End Date must be after Start Date', function (endDate) {
                const { startDate } = this.parent;

                // If either date is missing, skip validation
                if (!startDate || !endDate) return true;

                // Parse the dates to date object
                const start = DateUtil.parseDateString(startDate);
                const end = DateUtil.parseDateString(endDate);

                // Return true if end date is after start date
                return end > start;
            })
    });

    /**
     * Handles updating the date fields in formik when user changes something in respective fields
     * @param {Event} e - event triggered 
     * @param {string} fieldName - fieldName to update
     * @param {Function} setFieldValue - function that updates formik fields
     * @param {Object} values - formik values of all fields
     */
    const onDateChange = (e, fieldName, setFieldValue, values) => {
        const newValue = e.target.value;
        setFieldValue(fieldName, newValue);
        onChange({ ...values, [fieldName]: newValue });
    };

    return (
        <Formik
            initialValues={{ startDate: "", endDate: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onChange(values);
            }} >
            {({ values, setFieldValue }) => {
                // Resetting date and time, which will be called from parent component
                useImperativeHandle(ref, () => ({
                    resetDateTime() {
                        setFieldValue("startDate", "");
                        setFieldValue("endDate", "");
                        onChange({ startDate: "", endDate: "" });
                    }
                }));

                return (
                    <Form className="row">
                        <div className="col-md-6 pmivr-relative">
                            <PmivrLabel label="Start Date & Time" tooltip={TOOLTIP.INFO.CALL_LOGS.START_DATE} cssClass="my-0 pb-1" />
                            <Field name="startDate" type="text" className="form-control pmivr-input"
                                placeholder={`${MESSAGES.DATE_TIME_FORMAT}`}
                                onChange={(e) => onDateChange(e, 'startDate', setFieldValue, values)}
                                onKeyDown={(e) => AppUtil.isEnterKey(e) ? onChange(values, true) : null} />
                            <ErrorMessage name="startDate" component="div" className="field-error" />
                        </div>

                        <div className="col-md-6 pmivr-relative">
                            <PmivrLabel label="End Date & Time" tooltip={TOOLTIP.INFO.CALL_LOGS.END_DATE} cssClass="my-0 pb-1" />
                            <Field name="endDate" type="text" className="form-control pmivr-input"
                                placeholder={`${MESSAGES.DATE_TIME_FORMAT}`}
                                onChange={(e) => onDateChange(e, 'endDate', setFieldValue, values)}
                                onKeyDown={(e) => AppUtil.isEnterKey(e) ? onChange(values, true) : null} />
                            <ErrorMessage name="endDate" component="div" className="field-error" />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
});

PmivrDateTimeRangePicker.propTypes = {
    // Function which is called in the parent component on changing the date time range
    onChange: PropTypes.func
    // also used ref as a reference so that parent component can use its function
}

export default PmivrDateTimeRangePicker;
