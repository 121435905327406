import { useEffect, useRef, useState } from "react";
import { APP_PAGES } from "../../constants/app-pages";
import { Link } from "react-router-dom";
import { MESSAGES } from "../../constants/messages";
import AppUtil from "../../util/app.util";
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../components/common/tooltip/pmivr-tooltip";
import DeploymentEnvironmentForm from "./components/deployment-environment-form";
import PmivrSnackBar from "../../components/common/dialog/pmivr-snackbar";
import PmivrSearchInput from "../../components/common/search-input/pmivr-search";
import ConfigService from "../../services/config.service";
import UserService from "../../services/user.service";

/**
 * Lists all the deployment environments.
 * Also provides option to add new deployment environments
 * @returns {React.Component} Html element to render
 */
const DeploymentEnvironments = () => {

    const TITLE = { ADD_DEPLOYMENT_ENVIRONMENT: "Add Environment", EDIT_DEPLOYMENT_ENVIRONMENT: "Edit Environment" };

    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // filter object for filtering the environment
    const [filterEnvironment, setFilterEnvironment] = useState({ key: '' });

    //snackbar, split view (option menu modal)
    const [uiState, setUiState] = useState({
        isSplitView: false, dialogTitle: "",
        snackbar: { isOpen: false, msg: "" }
    });

    // configured environments
    const [deploymentEnvironments, setDeploymentEnvironments] = useState({ environments: [], cachedEnvironments: [] });

    // selected environment from the accordian
    const [selectedEnvironment, setSelectedEnvironment] = useState({ environment: {} });

    useEffect(() => {
        const init = async () => {
            await loadDeploymentEnvironments();
        }
        init();
    }, []);

    /**
     * Effect to load call logs when the filterEnvironment state changes.
     * @function useEffect
     * @dependencies {Array} filterEnvironment - The dependency array that triggers the effect when its value changes.
     */
    useEffect(() => {
        loadDeploymentEnvironments(filterEnvironment?.key);
    }, [filterEnvironment])

    // loading the environments from the DB
    const loadDeploymentEnvironments = async (envKey = "") => {
        try {
            const filter = { envKey };
            const environments = await ConfigService.getDeploymentEnvironments(filter);

            // if there is filter envKey, then update only the cached environments, not actual environment data
            (envKey) ? setDeploymentEnvironments(
                { ...deploymentEnvironments, cachedEnvironments: environments }
            ) : setDeploymentEnvironments(
                { ...deploymentEnvironments, environments: environments, cachedEnvironments: environments }
            )
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * updates the state with the updated environment
     * close the modal by setting flag to false
     * @param {object} environment environment to be updated
     * @param isExistingRecord is existing environment
     */
    const handleCloseAction = async (environment, isExistingRecord = false) => {
        // while closing the dialog, if there is any update in environment list, then update the state
        if (environment) {
            updateEnvironmentInState(environment, isExistingRecord);
        }
        // close the view
        setUiState({ ...uiState, isSplitView: false });
    }

    /**
     * updates the list with the updated environment
     * @param {array} environmentList environment list in which environment is to be updated
     * @param {object} environment environment to be updated
     * @returns {array} updated environment list
     */
    const updateEnvList = async (environmentList, environment) => {
        let envIndex = environmentList?.findIndex(
            (env) => { return env.key === environment.key }
        );

        if (envIndex !== -1) {
            environmentList[envIndex] = environment;
        }
        return environmentList;
    }

    /**
     * updates the environment list and cached environment list in state with updated environment
     * @param {object} environment environment to be added / updated
     * @param {boolean} isExistingRecord is existing environment
     */
    const updateEnvironmentInState = async (environment, isExistingRecord = false) => {
        if (environment) {
            if (isExistingRecord) {
                // updating the cached list of environment
                const cachedEnvList = await updateEnvList(deploymentEnvironments?.cachedEnvironments, environment);
                // updating the actual list of environment
                const envList = await updateEnvList(deploymentEnvironments?.environments, environment);
                setDeploymentEnvironments(
                    { ...deploymentEnvironments, environments: envList, cachedEnvironments: cachedEnvList }
                )
            } else {
                // getting the original list of environment from the state
                const environmentData = deploymentEnvironments.environments;
                // adding the new environment onto the list at the first position
                const updatedEnvironments = [environment, ...environmentData];
                setDeploymentEnvironments(
                    { ...deploymentEnvironments, environments: updatedEnvironments, cachedEnvironments: updatedEnvironments }
                );
            }
        }
    }

    const resetData = () => {
        setFilterEnvironment({ ...filterEnvironment, key: '' });
        setDeploymentEnvironments(
            { ...deploymentEnvironments, cachedEnvironments: deploymentEnvironments.environments }
        );
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> /
                                <Link to={APP_PAGES.SETTINGS}>Settings</Link>  / Deployment Environment
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="row pt-2 ">
                        <div className="col-lg-6">
                            <div className="px-3 ps-1 pmivr-breadcrumb-list ">
                                <h4> <Link to={APP_PAGES.SETTINGS}><i className="bi bi-arrow-left arrow-left"></i></Link>  Deployment Environment</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-2 pt-0">
                    <div className="col-md-3">
                        {/*Uses common search component to render the search input for filtering deployment-environments */}
                        <PmivrSearchInput
                            value={filterEnvironment?.key}
                            placeholder="Search environment key"
                            onSearch={(searchValue) => {
                                setFilterEnvironment({ ...filterEnvironment, key: searchValue });
                                if (!searchValue) {
                                    // Load all environments if no search text
                                    setDeploymentEnvironments({
                                        ...deploymentEnvironments,
                                        cachedEnvironments: deploymentEnvironments.environments
                                    });
                                }
                            }}
                            onEnter={() => loadDeploymentEnvironments(filterEnvironment?.key)}
                            label="Search Environment"
                        />
                    </div>
                    <div className={!(filterEnvironment?.key)
                        ? `pmivr-disabled col-md-2 d-flex align-items-end`
                        : `col-md-2 d-flex align-items-end`}>
                        <button className="float-start pmivr-btn-secondary pmivr-reset-link"
                            disabled={!(filterEnvironment.key)}
                            onClick={() => resetData()}>
                            Reset
                        </button>
                    </div>
                    <div className="col-md-7 d-flex justify-content-end align-items-end">
                        {UserService.hasPermission() && (
                            <div className="pmivr-relative float-end pe-1">
                                <PmivrTooltip message={"Click to add the new evironment"} position={TOOLTIP_POSITIONS.TOP}>
                                    <button type="button"
                                        onClick={() => {
                                            // clearing all the selections for the environment
                                            setSelectedEnvironment({
                                                ...selectedEnvironment,
                                                environment: {}
                                            });
                                            // opening the dialog for add environemnt
                                            setUiState({
                                                ...uiState,
                                                dialogTitle: TITLE.ADD_DEPLOYMENT_ENVIRONMENT,
                                                isSplitView: true
                                            });
                                        }}
                                        className="pmivr-btn-app pmivr-reset-link">
                                        <i className="bi bi-database-add"></i> Add Environment
                                    </button>
                                </PmivrTooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div >
            <div className="row me-2 ms-2">
                <div className={`${uiState.isSplitView ? "col-md-7" : "col-md-12"}`}>
                    <table className="table pmivr-accordian-table pmivr-table table-body-block header-fixed border mt-2">
                        <thead>
                            <tr key={"main-heading"}>
                                <th width="10%" className="text-center">Key</th>
                                <th width="10%" className="text-center">Name</th>
                                <th width="30%" className="text-center">Gateway Url</th>
                                <th width="10%" className="text-center">Created On</th>
                                <th width="10%" className="text-center">Updated On</th>
                                <th width="10%" className="text-center">Created By</th>
                                <th width="10%" className="text-center">Updated By</th>
                                <th width="10%" className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(deploymentEnvironments?.cachedEnvironments?.length) ?
                                [...deploymentEnvironments?.cachedEnvironments?.values()].map((environment, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td width="10%" title={environment.key} className="text-center pt-2">{environment.key}</td>
                                                <td width="10%" title={environment.name} className="text-center pt-2">{environment.name}</td>
                                                <td width="30%" title={environment.url} className="text-center pt-2">{environment.url}</td>
                                                <td width="10%" title={environment.createdOn} className="text-center pt-2">
                                                    {AppUtil.formatDateInLocal(environment.createdOn)}
                                                </td>
                                                <td width="10%" title={environment.updatedOn} className="text-center pt-2">
                                                    {AppUtil.formatDateInLocal(environment.updatedOn)}
                                                </td>
                                                <td width="10%" title={environment.createdBy} className="text-center pt-2">
                                                    {environment.createdBy}
                                                </td>
                                                <td width="10%" title={environment.updatedBy} className="text-center pt-2">
                                                    {environment.updatedBy}
                                                </td>
                                                {UserService.hasPermission() &&
                                                    <td width="10%" className="text-center pt-2">
                                                        <span>
                                                            <PmivrTooltip message="Edit Environment Url">
                                                                <button className="pmivr-btn-transparent"
                                                                    onClick={() => {
                                                                        setUiState(
                                                                            {
                                                                                ...uiState,
                                                                                dialogTitle:
                                                                                    TITLE.EDIT_DEPLOYMENT_ENVIRONMENT,
                                                                                isSplitView: true
                                                                            });
                                                                        setSelectedEnvironment({
                                                                            ...selectedEnvironment,
                                                                            environment: environment
                                                                        });
                                                                    }}>
                                                                    <i className="bi bi-pencil-square pmivr-btn-icon"></i>
                                                                </button>
                                                            </PmivrTooltip>
                                                        </span>
                                                    </td>
                                                }
                                            </tr>
                                        </>
                                    )
                                })
                                : <tr><td>No Environment Configured</td></tr>}
                        </tbody>
                    </table>
                </div>
                {uiState.isSplitView &&
                    <div className={`col-md-${uiState.isSplitView ? '5' : '0'}`}>
                        <DeploymentEnvironmentForm title={uiState.dialogTitle}
                            selectedEnvironment={selectedEnvironment}
                            closeAction={handleCloseAction} />
                    </div>
                }
            </div>
        </>
    )
}

export default DeploymentEnvironments;