import { useState, useRef } from "react";

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from "formik";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { REGEX } from "../../../config/config";

import AppUtil from "../../../util/app.util";

import PmivrSnackbar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../components/common/label/pmivr-label";

import AuthService from "../../../services/auth.service";

/**
 * Form for adding new API Keys
 * @returns {React.Component} Html element to render
 */
const ApiKeyModal = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // ui state object having following flags
    // disabled: flag to enable / disable the form on sub,it
    // message: object having message text and error specifying flag
    const [uiState, setUiState] = useState({ showKey: false, disableForm: false, message: { text: '', isError: false } });

    // API key generated
    const [apiKey, setApiKey] = useState("");

    // validating the formik fields
    const validate = Yup.object({
        appCode: Yup.string().required('App code is required'),
        email: Yup.string().matches(REGEX.EMAIL, MESSAGES.ERR.INVALID_EMAIL).required('Email is required'),
        apiKeyName: Yup.string().required('API Key Name is required'),
        apiKeyValidityDays: Yup.number().integer('Expiry time must be only numbers (no decimal)').positive('Expiry time must be a positive number').required('Expiry time is required')
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { email: "", appCode: '', apiKeyName: '', apiKeyValidityDays: 0 },
        validationSchema: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    /**
     * Adding new api key in database
     * @param {{ email, appCode, apiKeyName, apiKeyValidityDays }} info about the api key
     */
    const handleSubmit = async (keyInfo) => {
        setUiState({ ...uiState, disableForm: true });
        try {
            // saving new key.
            const response = await AuthService.saveApiKey(keyInfo);
            // api key generated at backend
            const apiKey = response?.data.apiKey;
            // Adding a new attribute for api key generated
            keyInfo.apiKey = apiKey;
            setApiKey(apiKey);
            setUiState({ showKey: true, disableForm: true, message: { text: response?.msg, isError: false } });

            // giving timeout to remain on the same screen for displaying message
        } catch (err) {
            let _message = MESSAGES.SOMETHING_WENT_WRONG;
            if (err.response?.status === 403) {
                _message = MESSAGES.ERR.UNAUTHORIZED;
            } else if (err.response?.status === 409) {
                _message = MESSAGES.ERR.EXISTING_EMAIL;
            }
            setUiState({ disableForm: false, message: { text: _message, isError: true } });
        }
    }

    /**
     * Copy the API key to clipboard
     */
    const copyApiKeyToClipboard = async () => {
        await AppUtil.copyToClipboard(apiKey);
        // opening the snackbar
        snackbarRef.current.open(MESSAGES.COPIED_TO_CLIPBOARD);
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-number-input">
                <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2 remove-arrows">
                                {/* Email associated with the API key */}
                                <PmivrLabel label="Email" tooltip={TOOLTIP.INFO.API_KEY_EMAIL} />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_KEY_EMAIL}>
                                    <input type="text" name="email" id="email" value={formik.values.email || ""}
                                        placeholder="Enter Email" className="form-control pmivr-input"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={uiState.disableForm} />
                                </PmivrOverlayTrigger>
                                {formik.touched.email && formik.errors.email && (
                                    <div className='field-error'>{formik.errors.email}</div>
                                )}

                                {/* App code associated with the API key */}
                                <PmivrLabel label="App Code" tooltip={TOOLTIP.INFO.API_KEY_APP_CODE} cssClass="mt-3" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_KEY_APP_CODE}>
                                    <input type="text" name="appCode" id="appCode" value={formik.values.appCode || ""}
                                        placeholder="Enter App code" className="form-control pmivr-input"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={uiState.disableForm} />
                                </PmivrOverlayTrigger>
                                {formik.touched.appCode && formik.errors.appCode && (
                                    <div className='field-error'>{formik.errors.appCode}</div>
                                )}

                                {/* Name of the api key */}
                                <PmivrLabel label="API Key Name" tooltip={TOOLTIP.INFO.API_KEY_NAME} cssClass="mt-3" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_KEY_NAME}>
                                    <input type="text" name="apiKeyName" id="apiKeyName" value={formik.values.apiKeyName || ""}
                                        placeholder="Enter Key Name" className="form-control pmivr-input"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={uiState.disableForm} />
                                </PmivrOverlayTrigger>
                                {formik.touched.apiKeyName && formik.errors.apiKeyName && (
                                    <div className='field-error'>{formik.errors.apiKeyName}</div>
                                )}

                                {/* Validity days for api key */}
                                <PmivrLabel label="Validity Days" tooltip={TOOLTIP.INFO.API_KEY_VALIDITY_DAYS} cssClass="mt-3" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_KEY_VALIDITY_DAYS}>
                                    <input type="number" name="apiKeyValidityDays" id="apiKeyValidityDays" value={formik.values.apiKeyValidityDays || ""}
                                        placeholder="No. of days" className="form-control pmivr-input"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={uiState.disableForm} />
                                </PmivrOverlayTrigger>
                                {formik.touched.apiKeyValidityDays && formik.errors.apiKeyValidityDays && (
                                    <div className='field-error'>{formik.errors.apiKeyValidityDays}</div>
                                )}

                                {/* After the key is regenerated, show the new key  */}
                                {uiState.showKey && (
                                    <>
                                        <div className="pmivr-label">
                                            <label className="pt-3">API Key Generated </label>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" className="form-control pmivr-input mb-3"
                                                name="apiKey" autoFocus required disabled
                                                value={apiKey}></input>
                                            <div className="input-group-append">
                                                <button className="pmivr-btn-secondary" type="button" onClick={copyApiKeyToClipboard} style={{ height: "50px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >Copy </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <button className={`pmivr-btn-app w-100 p-3 mt-2`}
                                disabled={uiState.disableForm || formik.errors.email || formik.errors.appCode || formik.errors.apiKeyName || formik.errors.apiKeyValidityDays
                                    || !formik.values.appCode || !formik.values.email || !formik.values.apiKeyName || !formik.values.apiKeyValidityDays} type="submit">
                                Generate API Key</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

ApiKeyModal.propTypes = {
    // closing the user dialogue
    closeAction: PropTypes.func
}

export default ApiKeyModal;