import { ATTRIBUTE_VALUE } from "../../../constants/attributes";
import { TASK_TYPE } from "../../../constants/task-types";

import Play from "./images/play";
import Prompt from "./images/user-option";
import Input from "./images/user-input";
import VoiceRecordStart from "./images/voice-record-start";
import VoiceRecordStop from "./images/voice-record-stop";

/**
 * Adding our custom editing controls and their actions to the pallete
 */
export default class CustomPalette {
  constructor(bpmnFactory, create, elementFactory, palette, translate) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const { bpmnFactory, create, elementFactory, translate } = this;

    /**
     * initializing attributes while creating task for play voice file
     */
    function createPlayVoiceFileTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");
        businessObject.voiceFilePath = "";
        businessObject.taskType = TASK_TYPE.playVoiceFile;

        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for hang up
     */
    function createPlayHangUpTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");

        businessObject.voiceFilePath = "";
        businessObject.taskType = TASK_TYPE.playVoiceFile;
        businessObject.taskName = ATTRIBUTE_VALUE.VOICE_TASK_NAME_HANGUP;

        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for transfer call
     */
    function createTransferTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");

        businessObject.voiceFilePath = "";
        businessObject.commonBusinessHours = true;
        businessObject.isTransferTask = true;
        businessObject.taskType = TASK_TYPE.transferCall;
        businessObject.zeroOutVoicefile = "pls-hold-transferring-call";

        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for prompt data
     */
    function createSayDataTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");
        businessObject.isSayData = true;
        businessObject.taskType = TASK_TYPE.sayData;
        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for option user
     */
    function createOptionUserTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:UserTask");

        businessObject.isOptionInput = true;
        businessObject.voiceFilePath = "";
        businessObject.taskType = TASK_TYPE.promptUserOption;
        businessObject.multiVoiceFileToggle = false;
        businessObject.bindDataToggle = false;

        const shape = elementFactory.createShape({
          type: "bpmn:UserTask",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for user input
     */
    function createpromptUserInputTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:UserTask");

        businessObject.isUserInput = true;
        businessObject.voiceFilePath = "";
        businessObject.taskType = TASK_TYPE.promptUserInput;

        const shape = elementFactory.createShape({
          type: "bpmn:UserTask",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for service implementation
     */
    function createServiceImplementationTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:ServiceTask");

        businessObject.isServiceImpl = "true";
        businessObject.taskType = TASK_TYPE.service;
        const shape = elementFactory.createShape({
          type: "bpmn:ServiceTask",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for loop prompt data
     */
    function createServiceLoopTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");

        businessObject.isLoop = "true";
        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for monitor voice start
     */
    function createVoiceRecordStartTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");
        businessObject.voiceFilePath = "";
        businessObject.taskType = "voiceRecordStart"; // type of the task is monitor voice stop
        businessObject.isVoiceRecordStart = true; // flag to represent monitor voice stop (being used at agi side to identify element)

        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating task for monitor voice stop
     */
    function createVoiceRecordStopTask() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:Task");
        businessObject.voiceFilePath = "";
        businessObject.taskType = "voiceRecordStop"; // type of the task is monitor voice stop
        businessObject.isVoiceRecordStop = true; // flag to represent monitor voice stop (being used at agi side to identify element)

        const shape = elementFactory.createShape({
          type: "bpmn:Task",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating start event
     */
    function createStartEvent() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:StartEvent");

        const shape = elementFactory.createShape({
          type: "bpmn:StartEvent",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    /**
     * initializing attributes while creating condition
     */
    function createGatewayConditon() {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:ExclusiveGateway");

        const shape = elementFactory.createShape({
          type: "bpmn:ExclusiveGateway",
          businessObject: businessObject,
        });

        create.start(event, shape);
      };
    }

    // json metadata for different types of controls and its actions (dragging and clicking creates a new node task)
    return {
      // start event
      "create.start-event": {
        group: "activity",
        title: translate("Create Start Event"),
        action: {
          dragstart: createStartEvent(),
          click: createStartEvent(),
        },
        name: "Start",
      },
      // gateway condition
      "create.exclusive-gateway": {
        group: "activity",
        title: translate("Set up a conditional decision point"),
        action: {
          dragstart: createGatewayConditon(),
          click: createGatewayConditon(),
        },
        name: "Condition",
      },
      // voice file task
      "create-play-voice-file-task": {
        group: "activity",
        title: translate("Create Task To Play Voice File"),
        imageUrl: Play.dataURL,
        action: {
          dragstart: createPlayVoiceFileTask(),
          click: createPlayVoiceFileTask(),
        },
        name: "Audio",
      },
      // option user task
      "create-option-user-task": {
        group: "activity",
        imageUrl: Prompt.dataURL,
        title: translate("Create Task for Option selection from User"),
        name: "option",
        action: {
          dragstart: createOptionUserTask(),
          click: createOptionUserTask(),
        },
      },
      // user input task
      "create-user-input-task": {
        group: "activity",
        imageUrl: Input.dataURL,
        title: translate("Create Task to accept input from User"),
        action: {
          dragstart: createpromptUserInputTask(),
          click: createpromptUserInputTask(),
        },
        name: "Input",
      },
      "create-service-impl-task": {
        group: "activity",
        imageUrl: Input.dataURL,
        title: translate("Create Task for Service Implementation"),
        action: {
          dragstart: createServiceImplementationTask(),
          click: createServiceImplementationTask(),
        },
        name: "Service",
      },
      "create-loop-task": {
        group: "activity",
        imageUrl: Input.dataURL,
        title: translate("Create Task for Looping"),
        action: {
          dragstart: createServiceLoopTask(),
          click: createServiceLoopTask(),
        },
        name: "Service",
      },
      "create-say-data-task": {
        group: "activity",
        title: translate("Create Task To Say given data"),
        imageUrl: Play.dataURL,
        action: {
          dragstart: createSayDataTask(),
          click: createSayDataTask(),
        },
        name: "Audio",
      },
      "create-hangup-task": {
        group: "activity",
        title: translate("Create Task To Hang Up"),
        imageUrl: Play.dataURL,
        action: {
          dragstart: createPlayHangUpTask(),
          click: createPlayHangUpTask(),
        },
        name: "Audio",
      },
      "create-transfer-task": {
        group: "activity",
        title: translate("Create Task To Transfer Call"),
        imageUrl: Play.dataURL,
        action: {
          dragstart: createTransferTask(),
          click: createTransferTask(),
        },
        name: "Transfer",
      },
      "create-voice-record-start-task": {
        group: "activity",
        title: translate("Create Task to Start Voice Recording"),
        imageUrl: VoiceRecordStart.dataURL,
        action: {
          dragstart: createVoiceRecordStartTask(),
          click: createVoiceRecordStartTask(),
        },
        name: "Voice Record Start",
      },
      "create-voice-record-stop-task": {
        group: "activity",
        title: translate("Create Task to Stop Voice Recording"),
        imageUrl: VoiceRecordStop.dataURL,
        action: {
          dragstart: createVoiceRecordStopTask(),
          click: createVoiceRecordStopTask(),
        },
        name: "Voice Record Stop",
      }
    };
  }
}

CustomPalette.$inject = [
  "bpmnFactory",
  "create",
  "elementFactory",
  "palette",
  "translate",
];