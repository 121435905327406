import { useFormik } from "formik";
import { useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";
import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";
import { APP_CONFIG } from "../../../config/config";
import { TOOLTIP } from "../../../constants/messages";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import AppUtil from "../../../util/app.util";
import ConfigService from "../../../services/config.service";

/**
 * Form for configuring new environment and update the existing configured environment gateway service url 
 * @param {{ closeAction, title, selectedEnvironment }} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const DeploymentEnvironmentForm = (props) => {

    const { title, closeAction } = props;

    const [selectedEnvironment, setSelectedEnvironment] = useState(props.selectedEnvironment);

    // ui state object having following flags
    // disabled: flag to enable / disable the button
    // message: object having message text and error specifying flag
    // isEdit: flag representing there is update in environment
    // dialogTitle: title for the confirmation dialog
    // showDialog: flag to show / hide the confirmation dialog
    const [uiState, setUiState] = useState({
        disabled: false, message: { text: '', isError: false }, isEdit: selectedEnvironment?.environment?.key || false,
        dialogTitle: selectedEnvironment?.environment?.key ? 'Edit Environment' : 'Add Environment', showDialog: false
    });

    // validation for the formik fields
    const validate = Yup.object({
        envName: Yup.string().required('Required'),
        envKey: Yup.string().required('Required'),
        envUrl: Yup.string().required('Required')
    });

    // formik values for form
    const formik = useFormik({
        initialValues: {
            envName: selectedEnvironment?.environment?.name || '',
            envKey: selectedEnvironment?.environment?.key || '',
            envUrl: selectedEnvironment?.environment?.url || ''
        },
        validationSchema: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    /**
     * Save the new environment or update the existing environment details 
     */
    const saveEnvironmentDetails = async () => {
        try {
            const envDetails = formik.values;
            let response;
            if (uiState.isEdit) {
                // updating the existing environment
                response = await ConfigService.updateDeploymentEnvironment(envDetails);
            } else {
                // saving the new environment
                response = await ConfigService.saveDeploymentEnvironment(envDetails);
            }

            setUiState({ ...uiState, disabled: true, showDialog: false, message: { text: response?.msg, isError: false } });
            // giving timeout to remain on the same screen for displaying message
            setTimeout(() => { closeAction(response?.environment, uiState.isEdit); }, APP_CONFIG.MESSAGE_TIMEOUT);
        } catch (err) {
            const _message = AppUtil.getErrorMsg(err?.response?.status, err?.response?.data?.msg);
            setUiState({ ...uiState, disabled: false, message: { text: _message, isError: true } });
        }
    }

    /**
     * On submission of formik form, disabling the button and showing the confirmation box for it.
     */
    const handleSubmit = () => {
        setUiState({ ...uiState, disabled: true, showDialog: true });
    }

    return (
        <>
            <div className="environment-form-container">
                <h4 className="p-4 border-bottom border-top">{title}</h4>
                <div className={uiState.message.isError
                    ? "field-error text-center" : "field-success text-center"}>
                    {uiState.message?.text}
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2 remove-arrows">
                                <PmivrLabel label="Environment Key" tooltip={TOOLTIP.INFO.ENV_KEY} cssClass="mt-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.ENV_KEY}>
                                    <input type="text" name="envKey" id="envKey" disabled={uiState?.isEdit}
                                        value={formik.values.envKey} autoFocus
                                        placeholder="Enter Environment Key" className="form-control pmivr-input" title=""
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                        }} onBlur={formik.handleBlur} />
                                </PmivrOverlayTrigger>
                                {formik.touched.envKey && formik.errors.envKey && (
                                    <div className='field-error'>{formik.errors.envKey}</div>
                                )}

                                <PmivrLabel label="Environment Name" tooltip={TOOLTIP.INFO.ENV_NAME} cssClass="mt-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.ENV_NAME}>
                                    <input type="text" name="envName" id="envName"
                                        value={formik.values.envName} title=""
                                        placeholder="Enter Environment Name" className="form-control pmivr-input"
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                        }} onBlur={formik.handleBlur} />
                                </PmivrOverlayTrigger>
                                {formik.touched.envName && formik.errors.envName && (
                                    <div className='field-error'>{formik.errors.envName}</div>
                                )}

                                <PmivrLabel label="Gateway Url" tooltip={TOOLTIP.INFO.ENV_URL} cssClass="mt-2" />
                                <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.ENV_URL}>
                                    <input type="text" name="envUrl" id="envUrl" value={formik.values.envUrl}
                                        placeholder="Enter Gateway Url" className="form-control pmivr-input" title=""
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                        }} onBlur={formik.handleBlur} />
                                </PmivrOverlayTrigger>
                                {formik.touched.envUrl && formik.errors.envUrl && (
                                    <div className='field-error'>{formik.errors.envUrl}</div>
                                )}

                            </div>

                            <div className="d-flex justify-content-end">
                                <PmivrTooltip message={TOOLTIP.CANCEL} position={TOOLTIP_POSITIONS.TOP}>
                                    <button type="button" className="pmivr-btn-cancel"
                                        onClick={() => closeAction()}>
                                        Cancel
                                    </button>
                                </PmivrTooltip>
                                <PmivrTooltip message={"Upload the selected flow file"} position={TOOLTIP_POSITIONS.TOP}>
                                    {/* disabling the button on valid form and
                                        uiState.disabled: true, when message is shown after update
                                        formik.dirty: True if any field has been modified.
                                        formik.isValid: True if the form is valid according to the validation schema */}
                                    <button type="submit"
                                        className={(uiState.disabled || !formik.dirty || !formik.isValid)
                                            ? 'pmivr-disabled pmivr-btn-app ms-2'
                                            : 'pmivr-btn-app ms-2'}
                                        disabled={uiState.disabled || !formik.dirty || !formik.isValid}>
                                        {uiState.isEdit ? 'Update' : 'Add'}
                                    </button>
                                </PmivrTooltip>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* Confirmation for the environment update */}
            <PmivrDialog showDialog={uiState.showDialog} title={uiState.dialogTitle}
                closeDialog={() => setUiState({ ...uiState, showDialog: false, disabled: false })}
                saveDialogChanges={saveEnvironmentDetails}
                message={"Are you sure to save the environment details ?"}
            />
        </>
    )
}

DeploymentEnvironmentForm.propTypes = {
    // selected environment to be updated ({envName, envKey, envUrl})
    selectedEnvironment: PropTypes.object,
    // title for the splitted block
    title: PropTypes.string,
    // closing the user dialogue
    closeAction: PropTypes.func,
}

export default DeploymentEnvironmentForm;