import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Chips from 'react-chips';

import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP } from "../../../constants/messages";
import { VoicePrompt } from "../../../models/voice-file";

import PmivrLabel from "../../common/label/pmivr-label";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import VoicePromptAccordion from "../../common/voice-prompt-accordion/voice-prompt-accordion";

import ElementService from "../../../services/element.service";

/**
 * Providing options (data-types) for readback and pattern in which readback will be prompted for input data 
 * during the agi call.
 * @param {{element, disabled}} props properties from parent component
 * @returns {React.Component} render readback properties for the user input
 */
const ReadbackInputData = (props) => {
    const { element, disabled } = props;
    // selected data type for readback data input
    const [selectedInputDataType, setSelectedInputDataType] = useState("");
    // Hide and show date readback format based on different readback types
    const [uiState, setUiState] = useState({ showDateReadbackFormat: false });
    // Available date variables as chips in the input field
    const [availableDateElements, setAvailableDateElements] = useState([]);
    const [confirmVoiceFileInfo, setConfirmVoiceFileInfo] = useState({});
    const { languagesConfigured } = useSelector(state => state.client);
    // Allowed readback elements a date can have in its readback pattern
    const [allowedDateElements, setAllowedDateElements] = useState([
        { label: "Full Year (yyyy)", value: "fullYear" },
        { label: "Year (yy)", value: "year" },
        { label: "Month (mm)", value: "month" },
        { label: "Day (dd)", value: "day" }
    ]);

    // selection options for input data types
    const dataTypeOptions = [
        { text: "Alpha", value: "alpha" },
        { text: "Digits", value: "digits" },
        { text: "Amount (Dollars & Cents)", value: "amount" },
        { text: "Number", value: "number" },
        { text: "Date", value: "date" }
    ];

    useEffect(() => {
        const init = () => {
            const readbackType = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE) || "alpha";
            setSelectedInputDataType(readbackType);
        }
        init();
    }, []);

    /**
     * Update readback pattern fields when the element is updated i.e. if type is changed of readback
     * In case of alpha, numeric input types readback pattern input field is shown, in which user can enter patterns such as (4,4,4) for pause between readbacks
     * In case of date readback, user can select from available options, what to prompt during readback, example: (month,year)
     */
    useEffect(() => {
        const handleDateReadbackChange = () => {
            const readbackType = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, "alpha");
            const dateReadbackPattern = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_DATE_READBACK_PATTERN);
            // Because date readback elements are comma separated values, to convert them into array
            const dateReadbackElementsArray = dateReadbackPattern?.split(',').map(item => item.trim());
            // Initially set the input field for date readback pattern as well
            setAvailableDateElements(dateReadbackElementsArray);

            // Check if the initial data type is "date" to conditionally render the read-back format field
            setUiState({ ...uiState, showDateReadbackFormat: (readbackType === "date") });
        };
        handleDateReadbackChange();
        getConfirmVoiceFileInfo();
    }, [element]);

    /**
     * Get the confirm voice file information for readback confirmation in user input, after input it is played for confirmation
     */
    const getConfirmVoiceFileInfo = () => {
        let confirmVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE);
        confirmVoiceFileInfo = JSON.parse(confirmVoiceFileInfo);
        const tmpConfirmVoiceFilePromptInfo = {};
        if (confirmVoiceFileInfo) {
            languagesConfigured.forEach((lang) => {
                if (confirmVoiceFileInfo[lang]?.voiceFileType) {
                    tmpConfirmVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: confirmVoiceFileInfo[lang]?.filePath, voiceFileType: confirmVoiceFileInfo[lang]?.voiceFileType })];
                } else {
                    tmpConfirmVoiceFilePromptInfo[lang] = confirmVoiceFileInfo[lang];
                }
            });
        }
        setConfirmVoiceFileInfo(tmpConfirmVoiceFilePromptInfo);
    }

    /**
     * Changes the value of date readback pattern when any value is deleted or added
     * @param {Array<string>} updatedDateReadbackPattern
     */
    const updateDateReadbackFormat = (updatedDateReadbackPattern) => {
        // Ensure availableDateElements is an array (initializing to empty array if undefined)
        const currentAvailableDateElements = availableDateElements || [];
        // Extract the labels from allowedDateElements
        const labelToValueMapping = allowedDateElements.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});

        // Map the updated labels back to their corresponding values
        const lastLabel = updatedDateReadbackPattern[updatedDateReadbackPattern.length - 1];
        const lastValue = labelToValueMapping[lastLabel];

        let updatedValues;
        if (updatedDateReadbackPattern.length > currentAvailableDateElements.length && !currentAvailableDateElements.includes(lastValue)) {
            // Case: A new chip has been added
            updatedValues = [...currentAvailableDateElements, lastValue];
        } else if (updatedDateReadbackPattern.length < currentAvailableDateElements.length) {
            // Case: A chip has been removed
            updatedValues = currentAvailableDateElements.filter(value => updatedDateReadbackPattern.includes(value));
        } else {
            // in case if user again selects value that is present in the pattern
            return;
        }

        // Update the available date elements (internal state)
        setAvailableDateElements(updatedValues);

        // Convert the updated readback pattern to a CSV string
        const dateReadbackElementsCsv = updatedValues.join(',');
        // Update the date readback pattern attribute in the element
        ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_DATE_READBACK_PATTERN, dateReadbackElementsCsv);
    };

    /**
     * updates the prompt list of invalid voice file and also updates the element with the updated list
     * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
     * @param {string} language selected language by the user to set the voice file
     */
    const handleUpdateConfirmPromptList = (updatedPromptsList, language) => {
        const updatedConfirmVoiceFileInfo = { ...confirmVoiceFileInfo, [language]: updatedPromptsList };
        setConfirmVoiceFileInfo(updatedConfirmVoiceFileInfo);
        ElementService.updateElementAttr(element, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE, JSON.stringify(updatedConfirmVoiceFileInfo));
    };

    return (
        <div disabled={disabled}>
            <div className="row">
                <div className="col">
                    <div className="form-group mb-3">
                        <div className={selectedInputDataType ? "pmivr-label" : "pmivr-hide-display"}>
                            <label className="pb-1">Input Data Type ?</label>
                        </div>
                        <select id="input-data-type" className="pmivr-select" disabled={disabled}
                            onChange={(event) => {
                                const newValue = event.target.value;
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, newValue);
                                setSelectedInputDataType(newValue);
                                // Show readback format only for "date" type
                                setUiState({ ...uiState, showDateReadbackFormat: (newValue === "date") });
                            }}
                            value={selectedInputDataType}
                        >
                            {dataTypeOptions.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            {!uiState.showDateReadbackFormat && (
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <PmivrLabel label={"Readback Data Pattern"} tooltip={TOOLTIP.INFO.USER_INPUT_READBACK_PATTERN} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_DATA_READBACK_PATTERN}>
                                <input id="userInputReadbackPattern" name="userInputReadbackPattern"
                                    className="form-control pmivr-input mt-1" type="text"
                                    value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_READBACK_PATTERN, "")}
                                    onChange={(event) => {
                                        ElementService.updateElement(
                                            element, ATTRIBUTES.USER_INPUT_READBACK_PATTERN, event.target.value
                                        );
                                    }}
                                    disabled={disabled} placeholder="Enter readback pattern (eg. 4,4,4,4)"
                                />
                            </PmivrOverlayTrigger>
                        </div>
                    </div>
                </div>
            )}

            {/* Conditionally render the Readback Format Field if selectedInputDataType is "date" */}
            {uiState.showDateReadbackFormat && (
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <PmivrLabel label={"Readback Format"} tooltip={TOOLTIP.INFO.USER_INPUT_DATE_READBACK_PATTERN} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_DATE_READBACK_PATTERN}>
                                <Chips value={availableDateElements} onChange={updateDateReadbackFormat}
                                    alwaysRenderSuggestions={true} fromSuggestionsOnly={true}
                                    suggestions={allowedDateElements.map((element) => element.label)} />
                            </PmivrOverlayTrigger>
                        </div>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col">
                    <div className="form-group mb-3">
                        {/* <Invalid option voice file with prompt list /> */}
                        <VoicePromptAccordion title="Input Confirmation Voice File" voicePromptInfo={confirmVoiceFileInfo} onSave={handleUpdateConfirmPromptList} />
                    </div>
                </div>
            </div>
        </div>
    );
}

ReadbackInputData.propTypes = {
    // attributes like isSpeechInput, grammer will be added on selected element
    element: PropTypes.object,
    /**
     * flag specifying whether the user input readback is enabled or not. 
     * If enabled, the configuration options (like readback pattern, readback datatype, etc) 
     * for readback is enabled. Otherwise, disabled.
     */
    disabled: PropTypes.bool
}

export default ReadbackInputData;