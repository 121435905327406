import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";

import VoicePromptSelection from "../../common/voice-prompt-selection/VoicePromptSelection.js";

import { ATTRIBUTES } from "../../../constants/attributes";
import { VoicePrompt } from "../../../models/voice-file.js";

import ElementService from "../../../services/element.service";
import DiagramService from "../../../services/diagram.service.js";

/**
 * Voice prompts view , with multiple prompts with variables configuration
 * To allow multiple prompts like in case of voice prompt , hangp case etc
 */
const VoicePromptsView = (props) => {
    // array of supported languages
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    // language selected
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    // information regarding voice file info
    const [voiceFileInfo, setVoiceFileInfo] = useState({});
    // key of the currently active accordion item
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    const [promptsList, setPromptsList] = useState([]);

    const { element } = props;

    // set the supported languages configured in wizard
    useEffect(() => {
        const init = async () => {
            const supportedLanguages = await DiagramService.getSupportedLanguages();
            setSupportedLanguages(supportedLanguages);
        }
        init();
    }, []);

    // on language change we need to get the list of updated prompts for that language
    useEffect(() => {
        const init = async () => {
            const voiceFileInfo = await getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO);
            setVoiceFileInfo(voiceFileInfo);
            if (Array.isArray(voiceFileInfo[selectedLanguage])) {
                setPromptsList(voiceFileInfo[selectedLanguage]);
            } else {
                const info = voiceFileInfo[selectedLanguage] || {};
                const promptInstance = new VoicePrompt(info);
                setPromptsList([promptInstance]);
            }
        }
        init();
        // on change of element state should get updated
    }, [selectedLanguage, element]);

    /**
     * Gets the voice file info object for the particular element
     * @param {Object} element 
     * @param {string} property 
     * @returns {{en:{{filePath,ttsText,voiceFileType}},es:{filePath,ttsText,voiceFileType}}}
     */
    const getVoiceFileInfo = async (element, property) => {
        const voiceFileInformation = element.businessObject.get(property) ?
            JSON.parse(element.businessObject.get(property))
            : {};
        return voiceFileInformation;
    }

    /**
     * updates the prompt list and also updates the element with the updated lsit
     * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
     */
    const handleUpdatePromptList = (updatedPromptsList) => {
        setPromptsList(updatedPromptsList);
        const updatedVoiceFileInfo = voiceFileInfo;
        updatedVoiceFileInfo[selectedLanguage] = updatedPromptsList;
        ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(updatedVoiceFileInfo));
    }

    return (
        <>
            <Accordion activeKey={activeAccordionKey} className="mt-3 pmivr-accordion"
                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                {
                    [...supportedLanguages].map((language) => {
                        return (
                            <div key={language}>
                                <Accordion.Item eventKey={language} className="mt-3 accordion-voice-item">
                                    <Accordion.Header onClick={() => setSelectedLanguage(language)}>
                                        <span className="pmivr-accordian-tab">
                                            Language : {language}
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="pmivr-card border-none p-0 mt-2">
                                        <VoicePromptSelection selectedLanguage={selectedLanguage}
                                            promptsList={promptsList} onChange={handleUpdatePromptList} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                        )
                    })
                }
            </Accordion>
        </>
    );
}

VoicePromptsView.propTypes = {
    element: PropTypes.object,
}

export default VoicePromptsView;