import { TASK_TYPE } from "./task-types";
import { MESSAGES } from "./messages";
import AppUtil from "../util/app.util";

// prefixes of custom elements
const CUSTOM_EL_PREFIX = {
  SERVICE: "serviceImpl"
}
// attributes in flow.xml
export const ATTRIBUTES = {
  XML_LOOPTASK_COLLECTION: 'loopTask:collection', VOICE_FILE_TASK_TYPE: "voice:taskType",
  PROMT_USER_OPTION_IS_OPTION: "usrOpt:isOptionInput", ALLOW_INPUT_DURING_PROMPT: "usrOpt:allowInputDuringPrompt",
  KEY_VALUE_USER_OPTION: "keyValueOpt:isKeyValueOption", KEY_VALUE_MAP: "keyValueOpt:keyValueMap",
  PROMT_USER_OPTION_OPTIONS: "usrOpt:options", PROMT_USER_OPTION_VALUE_MAP: "usrOpt:optionValuesMap",
  USER_OPTION_CALL_END_VOICE_FILE: "usrOpt:userOptionCallEndVoiceFileInfo", USER_OPTION_NO_INPUT_VOICE_FILE: "usrOpt:userOptionNoInputVoiceFileInfo",
  VOICE_FILE_INFO: "voice:voiceFileInfo", USER_OPTION_VALUES_MAP: 'usrOpt:optionValuesMap',
  CONFIRM_INPUT_VOICE_FILE: "usrInput:confirmInputVoiceFileInfo", USER_OPTION_INPUT_TIMEOUT: "usrOpt:optionInputTimeout",
  USER_INPUT_MIN_VALUE: 'usrInput:minValue', USER_INPUT_MAX_VALUE: 'usrInput:maxValue',
  USER_INPUT_MIN_VALUE_TYPE: 'usrInput:minValueType', USER_INPUT_MAX_VALUE_TYPE: 'usrInput:maxValueType',
  USER_INPUT_MAX_DIGITS: 'usrInput:maxDigits', USER_INPUT_READBACK_INPUT_DATA_TYPE: 'usrInput:readbackInputDataType',
  USER_INPUT_REQUIRE_PATTERNS: 'usrInput:requirePatternsUserInput', USER_INPUT_REQUIRE_PREFIX: 'usrInput:requirePrefixSymbolUserInput',
  SKIP_SINGLE_OPTION_CONFIGURED: "usrOpt:skipSingleOptionConfigured", END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES: 'usrOpt:endCallOnInvalidOptionInputAfterRetries',
  USER_INPUT_REQUIRE_SEPARATOR: 'usrInput:requireSeparatorUserInput', USER_INPUT_REQUIRE_VOICE_FILE: 'usrInput:requireVoiceFileUserInput',
  USER_INPUT_OPTION_INVALID_OPTION_FILE_TYPE: "userInputInvalidInputVoiceFileUplodaType",
  USER_INPUT_OPTION_INVALID_OPTION_FILE: "usrInput:userInputInvalidInputVoiceFile", USER_INPUT_MIN_DIGITS: 'usrInput:minDigits',
  USER_INPUT_READBACK_PATTERN: "usrInput:userInputReadbackPattern", USER_INPUT_REGEX_VALIDATION: 'usrInput:regexValidation',
  USER_INPUT_IS_REGEX_VALIDATION: 'usrInput:isRegexValidation', END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES: 'usrInput:endCallOnInvalidUserInputAfterRetries',
  USER_INPUT_OPTION_INPUT_VAR: "usrInput:inputVar", USER_INPUT_OPTION_RETRY_COUNT: "usrInput:userInputRetryCount",
  USER_INPUT_OPTION_TIMEOUT: "usrInput:userInputTimeout", USER_INPUT_OPTION_READBACK: "usrInput:userInputReadBack",
  USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT: "usrInput:userInputPromptLastDigitsCount", ALLOW_INPUT_DURING_READBACK: "usrInput:allowInputDuringReadback",
  USER_INPUT_OPTION_INPUT_PATTERN: "usrInput:userInputPattern", USER_INPUT_OPTION_INPUT_SEPARATOR: "usrInput:userInputPatternSeparator",
  USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL: "usrInput:userInputPrefixSymbol", USER_INPUT_OPTION_INPUT_SYMBOL_VOICE_FILE: "usrInput:userInputSymbolVoiceFile",
  USER_INPUT_PRECISION: "usrInput:userInputPrecision", SUBMIT_INPUT_WITH_HASH_KEY_ENABLED: "usrInput:isSubmitWithHashKeyEnabled",
  USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT: "usrInput:allowEmptyValuesAsInput",
  USER_INPUT_DATE_READBACK_PATTERN: "usrInput:dateReadbackPattern", USER_INPUT_CALL_END_VOICE_FILE: "usrInput:userInputCallEndVoiceFileInfo",
  DYNAMIC_IS_DYNAMIC_OPTION: "dynamicOpt:isDynamicOption", DYNAMIC_OPTION_COLLECTION: "dynamicOpt:optionCollection",
  DYNAMIC_OPTION_MAPPING_FIELD: "dynamicOpt:mappingField",
  DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES: "dynamicOpt:isCustomizeVoiceFiles", DYNAMIC_OPTION_MAPPING_BY: "dynamicOpt:mappingBy",
  // Possible values of the dynamic options list with their voice file paths. 
  // During runtime, values of dynamic list will play the voice files which are defined in this attribute
  DYNAMIC_OPTION_VOICE_FILES_FOR_OPTIONS: "dynamicOpt:voiceFilesForOptions",
  TRANSFER_NUMBER: "transfer:transferNumber", TRANSFER_START_TIME: "transfer:startTime",
  TRANSFER_COMMON_BUSINESS_HOURS: "transfer:commonBusinessHours",
  TRANSFER_END_TIME: "transfer:endTime", TRANSFER_HOLIDAY: "transfer:holiday",
  TRANSFER_ZERO_OUT_VOICE_FILE: "transfer:zeroOutVoicefile", TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE: "transfer:outsideBusinessHourVoiceFile",
  TRANSFER_ENDTIME0: "transfer:endTime0", TRANSFER_STARTTIME0: "transfer:startTime0",
  TRANSFER_ENDTIME1: "transfer:endTime1", TRANSFER_STARTTIME1: "transfer:startTime1",
  TRANSFER_ENDTIME2: "transfer:endTime2", TRANSFER_STARTTIME2: "transfer:startTime2",
  TRANSFER_ENDTIME3: "transfer:endTime3", TRANSFER_STARTTIME3: "transfer:startTime3",
  TRANSFER_ENDTIME4: "transfer:endTime4", TRANSFER_STARTTIME4: "transfer:startTime4",
  TRANSFER_ENDTIME5: "transfer:endTime5", TRANSFER_STARTTIME5: "transfer:startTime5",
  TRANSFER_ENDTIME6: "transfer:endTime6", TRANSFER_STARTTIME6: "transfer:startTime6",
  GATEWAY_CONDITION: "condition:condition", ATTRIBUTE_NAME: "name",
  USER_OPTION_RETRY_COUNT: "usrOpt:optionRetryCount", USER_OPTION_VAR: "usrOpt:optionVar",
  USER_OPTION_INVALID_OPTION_VOICE_FILE: "usrOpt:userOptionIvalidOptionVoiceFile",
  DYNAMIC_OPTION_RETRY_COUNT: "dynamicOpt:dynamicOptionRetryCount",
  DYNAMIC_OPTION_VAR: "dynamicOpt:dynamicOptionVar",
  SAY_DATA_TASK: "sayData:data", SAY_DATA_TYPE: "sayData:dataType", LOOP_TASK_ISLOOP: "loopTask:isLoop",
  // to specify voice files for possible values of loop task collection, so that we can replace voice files from builder itself
  // some fixed voice files are played for the collection which fetches the data during runtime only, 
  // we can change those voice files by using this attribute
  LOOP_TASK_VOICE_FILES_FOR_COLLECTION: "loopTask:voiceFilesForCollection",
  KEY_VALUE_OPTION_RETRY_COUNT: "keyValueOpt:keyValueOptionRetryCount",
  KEY_VALUE_OPTION_VAR: "keyValueOpt:keyValueOptionVar",
  VOICE_RECORD_START_FILE_PATH: "voiceRecordStart:voiceRecordStartFilePath",
  VOICE_RECORD_START_AUDIO_FORMAT: "voiceRecordStart:voiceRecordStartAudioFormat",
  IS_PAYMENT_AUTH_VOICE_RECORD_START: "voiceRecordStart:isPaymentAuthVoiceRecordStart",
  TASK_TYPE: 'taskType', USER_CONFIRMATION_OPTION: "usrConfirmation:ConfirmationOption",
  USER_CONFIRMATION_INPUT_VAR_TO_CONFIRM: "usrConfirmation:inputVarToConfirm",
  USER_CONFIRMATION_INPUT_CONFIRMATION_VAR: "usrConfirmation:inputConfirmationVar",
  USER_CONFIRMATION_RETRY_COUNT_VAR: "usrConfirmation:RetryCountVar", SESSION_STEP_NAME: "session:stepName",
  SERVICE_IMPL_EXPRESSION: "expression",
  SERVICE_IMPLEMENTATION_METHOD: "implementation", USER_OPTION_SPEECH_INPUT_GRAMMAR: "optionSpeechInputGrammar",
  USER_OPTION_IS_SPEECH_INPUT: "optionIsSpeechInput", USER_INPUT_SPEECH_INPUT_GRAMMAR: "userInputSpeechInputGrammar",
  USER_INPUT_IS_SPEECH_INPUT: "userInputIsSpeechInput",
  SERVICE: {
    // Service type method call | api call | expression
    TYPE: `${CUSTOM_EL_PREFIX.SERVICE}:type`,
    IMPLEMENTATION: `${CUSTOM_EL_PREFIX.SERVICE}:implementation`,
    API_METHOD: `${CUSTOM_EL_PREFIX.SERVICE}:apiMethod`,
    API_URL: `${CUSTOM_EL_PREFIX.SERVICE}:apiUrl`,
    API_PAYLOAD: `${CUSTOM_EL_PREFIX.SERVICE}:apiPayload`,
    API_QUERY_PARAMS: `${CUSTOM_EL_PREFIX.SERVICE}:apiQueryParams`,
    API_HEADERS: `${CUSTOM_EL_PREFIX.SERVICE}:apiHeaders`,
    RESPONSE_VARIABLES: `${CUSTOM_EL_PREFIX.SERVICE}:responseVariables`,
    AUTH: `${CUSTOM_EL_PREFIX.SERVICE}:auth`,
    EXPRESSION: `${CUSTOM_EL_PREFIX.SERVICE}:expression`
  }
};

// attribute values that can be set for attributes in the flow
export const ATTRIBUTE_VALUE = {
  VOICE_TASK_NAME_HANGUP: "HangUp"
};

// common attributes for all tasks
const COMMON_TASK_ATTRIBUTES = {
  "voiceFilePath": MESSAGES.EMPTY_STRING,
  "disabled": false
}

// attributes for creating an element
export const TASK_ATTRIBUTES = {
  VOICE_RECORD_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "taskType": "recordVoice",
    "isRecordVoice": true,
    "name": AppUtil.getTaskName('Record'),
    "isDisabledVoiceRecord": "false"
  },
  VOICE_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "taskType": TASK_TYPE.playVoiceFile,
    "name": AppUtil.getTaskName('Voice'),
  },
  OPTION_USER_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "isOptionInput": true,
    "taskType": TASK_TYPE.promptUserOption,
    "multiVoiceFileToggle": false,
    "bindDataToggle": false,
    "name": AppUtil.getTaskName('Option'),
    "isDisabledDynamicOpt": "false"
  },
  USER_INPUT_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "isUserInput": true,
    "taskType": TASK_TYPE.promptUserInput,
    "name": AppUtil.getTaskName('User_Input'),
  },
  SAY_DATA_TASK: {
    "isSayData": true,
    "name": AppUtil.getTaskName('Prompt'),
    "disabled": false
  },
  HANGUP_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "taskType": TASK_TYPE.playVoiceFile,
    "taskName": ATTRIBUTE_VALUE.VOICE_TASK_NAME_HANGUP,
    "name": AppUtil.getTaskName('HangUp'),
  },
  TRANSFER_CALL_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "commonBusinessHours": true,
    "isTransferTask": true,
    "taskType": TASK_TYPE.transferCall,
    "zeroOutVoicefile": "pls-hold-transferring-call",
    "name": AppUtil.getTaskName('Transfer'),
  },
  SERVICE_TASK: {
    "serviceImpl:isServiceImpl": true,
    "taskType": TASK_TYPE.service,
    "isServiceImpl": true,
    "name": AppUtil.getTaskName('Service'),
    "disabled": false
  },
  SUB_PROCESS: {
    "name": AppUtil.getTaskName('SubProcess')
  },
  VOICE_RECORD_START_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "taskType": "voiceRecordStart",
    "isVoiceRecordStart": true,
    "name": AppUtil.getTaskName('VoiceRecordStart'),
    "isDisabledVoiceRecordStart": "false"
  },
  VOICE_RECORD_STOP_TASK: {
    ...COMMON_TASK_ATTRIBUTES,
    "taskType": "voiceRecordStop",
    "isVoiceRecordStop": true,
    "name": AppUtil.getTaskName('VoiceRecordStop'),
    "isDisabledVoiceRecordStop": "false"
  },
};